import React from 'react';

import {
  getDateUTCRange,
  getCurrentUTCDate,
  formattedDate,
  UTCToLocalFormat,
  UTCToCurrentDiff,
} from '../../utils/date-utils';
import './style.scss';
import { ProgressContainer, SimpleCell } from '../../components/common';

const ReservationPlans = (props) => {
  const { loadingCustomerInfo, plans } = props;

  const initDateRange = getDateUTCRange(new Date());
  initDateRange.startDate = getCurrentUTCDate();
  const getPlanStatusValue = ({ endDays, startDays }) => {
    if (startDays > 0) {
      return `Comienza en ${startDays} ${startDays === 1 ? 'day' : 'days'} `;
    } else {
      if (endDays > 1) {
        return `Active (${endDays} Days)`;
      } else if (endDays === 1) {
        return `Active (1 Day)`;
      } else if (endDays <= 0) {
        return `Expired`;
      } else {
        return '';
      }
    }
  };

  const getPlanStatusColor = ({ endDays, startDays }) => {
    if (startDays > 0) {
      return `grey`;
    } else {
      return endDays < 3 ? 'red' : 'green';
    }
  };

  const AvailableSessionsLeft = ({ plans = [] }) => {
    return (
      <div className="availableSessionsLeftContainer">
        <ProgressContainer condition={loadingCustomerInfo}></ProgressContainer>
        {!loadingCustomerInfo &&
          plans.map((plan, index) => {
            let clasesUsadas =
              plan.planNumberOfSessions - plan.availablePlanSessions;

            if (clasesUsadas === plan.planNumberOfSessions) {
              clasesUsadas = 0;
            }

            let clasesDisponibles =
              plan.availablePlanSessions === null
                ? plan.planNumberOfSessions
                : plan.availablePlanSessions;

            const endDateDiff = UTCToCurrentDiff(plan.planEndDate);
            const startDateDiff = UTCToCurrentDiff(plan.planStartDate);
            return (
              <div className="availableSessionsLeft" key={`plan-${index}`}>
                <SimpleCell
                  className="plan_name"
                  label={'Nombre del plan'}
                  value={plan.planName}
                />
                <SimpleCell
                  className="plan_available_sessions"
                  label={'Clases Disponibles'}
                  value={clasesDisponibles}
                />

                <SimpleCell
                  className="plan_expiration_date"
                  label={'Fecha de expiración'}
                  value={UTCToLocalFormat(plan.planEndDate, 'LLLL')}
                />
                <SimpleCell
                  style={{
                    color: getPlanStatusColor({
                      endDays: endDateDiff.days,
                      startDays: startDateDiff.days,
                    }),
                  }}
                  className="plan_expiration_time"
                  label={'Estado'}
                  value={getPlanStatusValue({
                    endDays: endDateDiff.days,
                    startDays: startDateDiff.days,
                  })}
                />
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <>
      <div className="currentTimeStamp">
        {formattedDate(new Date(), 'LLLL')}
      </div>
      {plans && <AvailableSessionsLeft plans={plans} />}
    </>
  );
};

export default ReservationPlans;
