import React, { useState } from 'react';
import { CustomModal, DynamicList } from '../common';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import { usePickExpApi } from '@pickexp/core';
import './attendants-modal.scss';

export const AttendantsModal = ({ isOpen, onClose, customers }) => {
  const [selectedList, setSelectedList] = useState([]);
  const api = usePickExpApi().productApi;

  const confirmSessionAttendants = async () => {
    await api.confirmSessionAttendants({
      list: selectedList.map((v) => ({
        status: 'ATTENDED',
        activityUUID: v,
      })),
    });
    onClose();
  };

  return (
    <div className="attendantsModalContainer">
      <CustomModal isOpen={isOpen} onClose={onClose}>
        <div className="attendantsModalBodyContainer">
          <h3> Attendants </h3>
          <div className="listContainer">
            <DynamicList
              noHeader
              onRowClick={({ row }) => {}}
              data={customers}
              isLoading={false}
              columns={[
                {
                  key: 'name',
                  header: '',
                  render: ({ rowData }) => {
                    const hasAttended =
                      rowData.hasAttended === 1 ? true : false;
                    const isSelected =
                      selectedList &&
                      selectedList.includes(rowData.activityUUID);
                    return (
                      <div
                        onClick={() => {
                          if (!hasAttended) {
                            if (isSelected) {
                              const nl = selectedList.filter(
                                (v) => v !== rowData.activityUUID
                              );
                              setSelectedList(nl);
                            } else {
                              setSelectedList([
                                ...selectedList,
                                rowData.activityUUID,
                              ]);
                            }
                          }
                        }}
                        style={{
                          minHeight: '40px',
                          display: 'flex',
                          padding: '10px',
                          cursor: hasAttended ? 'not-allowed' : 'pointer',
                          color: hasAttended ? '#aeaeae' : '',
                        }}
                      >
                        {(isSelected || hasAttended) && <CheckIcon />}

                        {rowData.firstName}
                      </div>
                    );
                  },
                },
              ]}
            />
          </div>
          <div className="actionButtons">
            <Button
              onClick={() => {
                onClose();
              }}
              type="button"
            >
              Close
            </Button>
            <Button onClick={confirmSessionAttendants} type="button">
              Save
            </Button>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};
