import React, { useState, useCallback, useEffect } from 'react';
import { Search, ListContainer, DynamicList } from '../common';
import InstructorForm from './instructor-form';

import { ROUTE, ROUTE_PARAM } from '../../constants/routes';
import { usePickExpApi } from '@pickexp/core';
import { useLocation, useParams, useNavigate } from 'react-router-dom';

import InstructorListRow from './instructor-list-row';
import { textContains } from '../../utils/validations';
import {
  DEFAULT_CONFIRM_MODAL_STATE,
  ConfirmModal,
  COMMON_TEXT,
} from '../common';

import './style.scss';

const Instructors = () => {
  const params = useParams();
  let navigate = useNavigate();
  let location = useLocation();
  const [instructor, setInstructor] = useState();
  const [list, setList] = useState([]);
  const [instructorList, setInstructoList] = useState([]);
  const isListView = ROUTE.INSTRUCTORS === location.pathname;
  const [isLoading, setIsLoading] = useState(true);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(
    DEFAULT_CONFIRM_MODAL_STATE
  );
  const api = usePickExpApi().productApi;
  const getInstructors = useCallback(async () => {
    setIsLoading(true);
    const { data } = await api.getInstructors({});
    setHasLoaded(true);
    setList(data);
    setInstructoList(data);
    setIsLoading(false);
  }, [api]);

  const onRowClickHandler = ({ row }) => {
    setInstructor(row);
  };

  const onAddNewInstructorClickHandler = ({ row }) => {
    navigate(ROUTE.NEW_INSTRUCTOR);
  };

  const onDeleteHandler = ({ uuid, firstName }) => {
    setOpenConfirmModal({
      text: `Seguro que desea eliminar el instructror ${firstName}?`,
      isOpen: true,
      onAcceptCallback: async () => {
        await api.deleteInstructor({ uuid });
        getInstructors();
        setOpenConfirmModal({ isOpen: false });
      },
      onRejectCallback: () => {
        setOpenConfirmModal({ isOpen: false });
      },
    });
  };

  useEffect(() => {
    !hasLoaded && getInstructors();
  }, [hasLoaded, getInstructors]);

  return (
    <div>
      {isListView && (
        <Search
          placeholder={'Search instructor by name'}
          buttonText={'Add New instructor'}
          onClick={onAddNewInstructorClickHandler}
          onType={(value) => {
            const nList = instructorList.filter((row) =>
              textContains(row.firstName, value)
            );
            setList(nList);
          }}
        />
      )}
      <ListContainer>
        {isListView && (
          <DynamicList
            noHeader
            onRowClick={onRowClickHandler}
            data={list}
            isLoading={isLoading}
            columns={[
              {
                key: 'firstName',
                header: '',
                render: ({ rowData, index }) => {
                  return (
                    <InstructorListRow
                      key={`instructorListRow-${index}`}
                      onDelete={onDeleteHandler}
                      {...rowData}
                    />
                  );
                },
              },
            ]}
          />
        )}
      </ListContainer>
      {!isListView && (
        <InstructorForm
          instructor={params[ROUTE_PARAM.INSTRUCTOR_ID] ? instructor : {}}
          onSave={getInstructors}
        />
      )}
      <ConfirmModal
        isOpen={openConfirmModal.isOpen}
        bodyText={openConfirmModal.text}
        onAccept={openConfirmModal.onAcceptCallback}
        acceptText={COMMON_TEXT.YES}
        onReject={openConfirmModal.onRejectCallback}
        rejectText={COMMON_TEXT.NO}
      />
    </div>
  );
};

export default Instructors;
