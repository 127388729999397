import React, { useState, useCallback, useEffect } from 'react';
import {
  Search,
  ListContainer,
  DEFAULT_CONFIRM_MODAL_STATE,
  COMMON_TEXT,
  ConfirmModal,
} from '../common';
import { AttendantsModal } from './attendants-modal';
import { usePickExpApi } from '@pickexp/core';
import { ROUTE, ROUTE_PARAM, getRoutePath } from '../../constants/routes';
import { getDateUTCRange, getWeekDayName } from '../../utils/date-utils';
import { textContains } from '../../utils/validations';
import { SessionTabs } from '../SessionTabs';
import { useNavigate } from 'react-router-dom';
import { useAppState, APP_STATE_VALUES, useOrgConfig } from '../../hooks';

const ListSessions = () => {
  const orgConfig = useOrgConfig();

  const { appState, setAppState, showErrorAlert, showSuccessAlert } =
    useAppState();

  const currentSessionTab =
    appState && appState[APP_STATE_VALUES.CURRENT_SESSIONS_TAB];
  const currentWeekDayTabIndex =
    appState && appState[APP_STATE_VALUES.CURRENT_WEEK_DAY_TAB_INDEX];

  let navigate = useNavigate();
  const [list, setList] = useState([]);
  const [currentActiveTabIndex, setCurrentActiveTabIndex] = useState(
    currentWeekDayTabIndex || 0
  );
  const [currentWeekDay, setCurrentWeekDay] = useState(getWeekDayName());
  const [sessionList, setSessionList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = React.useState(
    DEFAULT_CONFIRM_MODAL_STATE
  );
  const [isShowSessionCustomersModalOpen, setIsShowSessionCustomersModalOpen] =
    useState(false);
  const [customers, setCustomers] = useState([]);
  const [dateRange, setDateRange] = useState({
    ...getDateUTCRange(new Date()),
    weekDay: getWeekDayName(),
  });

  const api = usePickExpApi().productApi;

  const getSessions = useCallback(
    async (date) => {
      setIsLoading(true);
      const { startDate, endDate, weekDay } = date || dateRange;
      const { data } = await api.getSessionsByDateRange({
        startDate,
        endDate,
        weekDay: weekDay || currentWeekDay,
        withStatusInactive: true,
      });

      setHasLoaded(true);
      setList(data);
      setSessionList(data);
      setIsLoading(false);
    },
    [api, dateRange, currentWeekDay]
  );

  const onRowClickHandler = ({ row }) => {};

  const onEditClickHandler = ({ uuid }) => {
    navigate(
      getRoutePath(ROUTE.EDIT_SESSION, {
        [ROUTE_PARAM.SESSION_ID]: uuid,
      })
    );
  };

  const onCancelClickHandler = async ({ uuid }) => {
    setOpenConfirmModal({
      text: `Seguro que desea cancelar esta clase?`,
      isOpen: true,
      onAcceptCallback: async () => {
        await api.createOrUpdateSession({
          isCanceled: true,
          uuid: uuid,
        });
        setOpenConfirmModal({ isOpen: false });
        getSessions();
      },
      onRejectCallback: () => {
        setOpenConfirmModal({ isOpen: false });
      },
    });
  };

  const onSwitchStatusClickHandler = async ({ uuid, isActive }) => {
    setOpenConfirmModal({
      text: `Seguro que desea cambiar el status de esta clase?`,
      isOpen: true,
      onAcceptCallback: async () => {
        await api.createOrUpdateSession({
          isActive,
          uuid: uuid,
        });
        setOpenConfirmModal({ isOpen: false });
        getSessions();
      },
      onRejectCallback: () => {
        setOpenConfirmModal({ isOpen: false });
      },
    });
  };

  const onNewClickHandler = () => {
    navigate(ROUTE.NEW_SESSION);
  };

  const onDeleteClickHandler = ({ uuid }) => {
    setOpenConfirmModal({
      text: `Seguro que desea eliminar esta clase?`,
      isOpen: true,
      onAcceptCallback: async () => {
        await api.deleteSession({
          uuid,
        });
        setOpenConfirmModal({ isOpen: false });
        getSessions();
      },
      onRejectCallback: () => {
        setOpenConfirmModal({ isOpen: false });
      },
    });
  };

  const showSessionCustomers = async ({ uuid }) => {
    const { startDate, endDate } = dateRange;
    const { data } = await api.getSessionCustomersByRange({
      endDate,
      startDate,
      sessionUUID: uuid,
    });
    setCustomers(data);
    setIsShowSessionCustomersModalOpen(true);
  };

  const sendSessionCustomersToInstructor = async ({ uuid }) => {
    const { startDate, endDate } = dateRange;
    try {
      const r = await api.generateCustomersByRangeLink({
        endDate,
        startDate,
        sessionUUID: uuid,
      });
      showSuccessAlert({ message: 'Attendance list sent' });
    } catch (err) {
      showErrorAlert({ message: 'Something went wrong try again later' });
    }
  };

  const tabChangeHandler = ({ formatedDate }, index) => {
    const newRange = getDateUTCRange(formatedDate);
    const newWeekDay = getWeekDayName(formatedDate);
    setDateRange(newRange);
    setCurrentActiveTabIndex(index);
    setCurrentWeekDay(newWeekDay);
    setAppState({
      ...appState,
      [APP_STATE_VALUES.CURRENT_SESSIONS_TAB]: {
        ...newRange,
        weekDay: newWeekDay,
      },
      [APP_STATE_VALUES.CURRENT_WEEK_DAY_TAB_INDEX]: index,
    });
    getSessions({ ...newRange, weekDay: newWeekDay });
  };

  useEffect(() => {
    !hasLoaded && getSessions(currentSessionTab);
  }, [hasLoaded, getSessions, currentSessionTab]);

  return (
    <div>
      <Search
        placeholder={`Search by session ${
          orgConfig.canUseInstructors ? 'or instrutor name' : ''
        }`}
        buttonText={'Add New Session'}
        onClick={onNewClickHandler}
        onType={(value) => {
          const nList = sessionList.filter(
            (row) =>
              textContains(row.name, value) ||
              textContains(row.instructorFirstName, value) ||
              textContains(row.instructorLastName, value)
          );
          setList(nList);
        }}
      />

      <ListContainer>
        <SessionTabs
          data={list}
          loading={isLoading}
          onTabChange={tabChangeHandler}
          onRowClick={onRowClickHandler}
          showSpots
          currentActiveTabIndex={currentActiveTabIndex}
          onActiveSwitchChange={async ({ value, uuid }) => {
            onSwitchStatusClickHandler({
              isActive: value === true ? true : false,
              uuid,
            });
          }}
          actionSection={{
            onEdit: onEditClickHandler,
            onClick: showSessionCustomers,
            onCancel: onCancelClickHandler,
            onDelete: onDeleteClickHandler,
            onSendAttendants: sendSessionCustomersToInstructor,
          }}
        />
      </ListContainer>
      <AttendantsModal
        isOpen={isShowSessionCustomersModalOpen}
        onClose={() => {
          setIsShowSessionCustomersModalOpen(false);
        }}
        customers={customers}
      />

      <ConfirmModal
        isOpen={openConfirmModal.isOpen}
        bodyText={openConfirmModal.text}
        onAccept={openConfirmModal.onAcceptCallback}
        acceptText={COMMON_TEXT.YES}
        onReject={openConfirmModal.onRejectCallback}
        rejectText={COMMON_TEXT.NO}
      />
    </div>
  );
};

export default ListSessions;
