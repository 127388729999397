import React from 'react';
import { UTCToLocalFormat, UTCToCurrentDiff } from '../../utils/date-utils';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { SimpleCell, DotMenu } from '../common';

const REASON = {
  CUSTOMER_CANCEL: 'CUSTOMER_CANCEL',
  RESERVATION: 'RESERVATION',
  CANCELED_BY_ORG_ON_BEHALF: 'CANCELED_BY_ORG_ON_BEHALF',
  ATTENDED_CONFIRMATION: 'Attendance Confirmation',
  ATTENDED: 'ATTENDED',
  ADJUSTMENT: 'ADJUSTMENT',
};

const HistorySessionRecordRow = ({ record, index }) => {
  const getReason = ({ reason }) => {
    if (reason === REASON.CUSTOMER_CANCEL) {
      return 'Canceled By Customer';
    }
    if (reason === REASON.CANCELED_BY_ORG_ON_BEHALF) {
      return 'Canceled By Business';
    }
    if (reason === REASON.ATTENDED || reason === REASON.ATTENDED_CONFIRMATION) {
      return 'Attended';
    }
    if (reason === REASON.RESERVATION) {
      return 'Reserved';
    }
    if (reason === REASON.ADJUSTMENT) {
      return 'Adjusted By Admin';
    } else {
      return reason;
    }
  };

  const getIsChild = ({ reason }) => {
    if (
      reason === REASON.CUSTOMER_CANCEL ||
      reason === REASON.CANCELED_BY_ORG_ON_BEHALF ||
      reason === REASON.ATTENDED ||
      reason === REASON.ATTENDED_CONFIRMATION
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getIsCancel = ({ reason }) => {
    if (
      reason === REASON.CUSTOMER_CANCEL ||
      reason === REASON.CANCELED_BY_ORG_ON_BEHALF
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isXWeight = Math.abs(record.weight) > 1;

  const isChild = getIsChild({ reason: record.reason });
  const isCancel = getIsCancel({ reason: record.reason });

  let statusText = null;

  if (!isChild || (isChild && isCancel)) {
    statusText = `${record.weight < 0 ? 'Discounted' : 'Refunded'} ${
      isXWeight ? `X${Math.abs(record.weight)}` : ''
    }`;
  }

  return (
    <div
      className={`${
        isChild ? 'historySessionChildRecordRow' : 'historySessionRecordRow'
      } 
      ${isCancel ? 'canceled' : 'other'}
      `}
    >
      <div>{UTCToLocalFormat(record.recordDate, 'LLLL')}</div>
      <div>{getReason({ reason: record.reason })}</div>
      {isChild && <div></div>}
      {isChild && <div></div>}
      {isChild && <div></div>}

      {!isChild && (
        <div>
          {record.sessionName && UTCToLocalFormat(record.startDate, 'LLLL')}
        </div>
      )}
      {!isChild && <div>{record.sessionName}</div>}
      {!isChild && <div>{record.sessionTypeName}</div>}

      <div
        style={{
          color: record.weight < 0 ? '' : '',
        }}
      >
        {statusText}
      </div>
    </div>
  );
};

export const HistoryRecordRow = ({
  record,
  index,
  onOpenAdvanceOptionModal,
}) => {
  const historySessionRecordRows = record.sessions || [];
  const currentTotal = historySessionRecordRows.reduce(
    (previousValue, currentValue) => {
      const cAvailable = previousValue.availableSessions + currentValue.weight;
      return {
        availableSessions: cAvailable,
      };
    },
    {
      availableSessions: record.weight,
    }
  );

  const dateDiff = UTCToCurrentDiff(record.endDate);
  const startDateDiff = UTCToCurrentDiff(record.startDate);

  const getPlanStatusValue = ({ endDays, startDays }) => {
    if (startDays > 0) {
      return `Schedule`;
    } else {
      if (endDays > 1) {
        return `Active (${dateDiff.days} Days)`;
      } else if (endDays === 1) {
        return `Active (${dateDiff.days} Day)`;
      } else if (endDays <= 0) {
        return `Expired`;
      } else {
        return '';
      }
    }
  };

  const getPlanStatusColor = ({ endDays, startDays }) => {
    if (startDays > 0) {
      return `grey`;
    } else {
      return endDays < 3 ? 'red' : 'green';
    }
  };

  const dorMenuItems = [
    {
      EditIcon: ArrowDropUpIcon,
      onClick: () => {
        onOpenAdvanceOptionModal &&
          onOpenAdvanceOptionModal({ isAdd: true, plan: record });
      },
      text: 'Increase Plan Sessions',
    },
    {
      EditIcon: ArrowDropDownIcon,
      onClick: () => {
        onOpenAdvanceOptionModal &&
          onOpenAdvanceOptionModal({ isAdd: false, plan: record });
      },
      text: 'Decrease Plan Sessions',
    },
  ];

  return (
    <div className={'historyRecordRow'}>
      <div className="menuOptions">
        <DotMenu items={dorMenuItems} id="historyRecordRowDotMenuItem" />
      </div>
      <div className="historyPlanContent">
        <SimpleCell
          label={'Plan Purchase Date'}
          value={UTCToLocalFormat(record.recordDate, 'LLLL')}
        />
        <SimpleCell label={'Plan Name'} value={record.planName} />
        <SimpleCell
          label={'Start Date'}
          value={UTCToLocalFormat(record.startDate, 'LLLL')}
        />
        <SimpleCell
          label={'End Date'}
          value={UTCToLocalFormat(record.endDate, 'LLLL')}
        />

        <SimpleCell
          label={'# Of Available Sessions'}
          value={`${currentTotal.availableSessions} of ${record.weight}`}
        />
        <SimpleCell
          style={{
            color: getPlanStatusColor({
              endDays: dateDiff.days,
              startDays: startDateDiff.days,
            }),
          }}
          label={'Plan Status'}
          value={getPlanStatusValue({
            endDays: dateDiff.days,
            startDays: startDateDiff.days,
          })}
        />
      </div>
      <div className="historySessionContent">
        {historySessionRecordRows.map(
          (sessionRecordRow, sessionRecordRowIndex) => (
            <HistorySessionRecordRow
              record={sessionRecordRow}
              index={sessionRecordRowIndex}
              key={`HistorySessionRecordRowK-${sessionRecordRowIndex}`}
            />
          )
        )}
      </div>
    </div>
  );
};
