import React from 'react';
import './style.scss';

export const SectionHeader = ({ title, subTitle, endIcon, onClick }) => {
  return (
    <div className={'section-header'} onClick={onClick}>
      <h2>
        {title}
        {endIcon}
      </h2>
      <label>{subTitle}</label>
    </div>
  );
};
