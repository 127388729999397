import UtilsService from './UtilsService';
import simpleWalletApis from './SimpleWalletApis';
import meApis from './MeApis';
import babyApis from './BabyApis';
import genderRevealApis from './GenderRevealApis';
import bookflowApis from './BookflowApis';
import pickExpApis from './PickExpApis';
import PeFetch from './PeFetch';
import LocalizationResources from '../constants/localization-resources';

export const PickexpApi = {
  bookflow: bookflowApis,
  'simple-wallet': simpleWalletApis,
  me: meApis,
  baby: babyApis,
  'gender-reveal': genderRevealApis,
  pickexp: pickExpApis,
  getBaseApis: ({ API_CONFIG }) => {
    const peFetch = PeFetch({ API_CONFIG });
    const { APP_ID, APP_PLATFORM, PRODUCTS } = API_CONFIG;
    const r = {
      fetch: peFetch,
      getCoreApiURLs: PickexpApi['pickexp']({ peFetch }).urls,
      getCoreApi: PickexpApi['pickexp']({ peFetch }),
      productApi:
        APP_ID && APP_PLATFORM && PickexpApi[APP_ID]
          ? PickexpApi[APP_ID]({ peFetch })
          : {},
      product: {},
    };

    for (let index in PRODUCTS) {
      const PRODUCT_KEY = PRODUCTS[index];
      if (PickexpApi[PRODUCT_KEY]) {
        r.product[PRODUCT_KEY] = PickexpApi[PRODUCT_KEY]({
          peFetch: PeFetch({
            APP_PLATFORM,
            APP_ID: PRODUCT_KEY,
          }),
        });
      }
    }

    return r;
  },
};
