import React, { useEffect, useCallback, useState } from 'react';
import { usePickExpApi } from '@pickexp/core';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import SessionTypeForm from './form';
import { DynamicList } from '../../common';
import SessionTypeListRow from './session-type-list-row';
import './style.scss';
import { useAppState } from '../../../hooks';
const SessionType = () => {
  const { showErrorAlert, showSuccessAlert } = useAppState();
  const [isLoading, setIsLoading] = useState(true);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [sessionTypesList, setSessionTypesList] = useState([]);
  const [sessionType, setSessionType] = useState('');
  const api = usePickExpApi().productApi;
  const getSessionTypes = useCallback(async () => {
    setIsLoading(true);
    const { data } = await api.getSessionTypes();
    setHasLoaded(true);
    setSessionTypesList(data);
    setIsLoading(false);
  }, [api]);

  const deleteSessionType = async ({ uuid }) => {
    const r = await api.deleteSessionType({ uuid });
    setSessionType('');
    getSessionTypes();
    if (r) {
      showSuccessAlert({
        message: 'Session type deleted',
      });
    } else {
      showErrorAlert({
        message: 'Error',
      });
    }
    return r;
  };

  const backToList = () => {
    setSessionType('');
    setShowForm(false);
    getSessionTypes();
  };

  useEffect(() => {
    getSessionTypes();
  }, [api, hasLoaded, getSessionTypes]);

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            margin: '50px',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div className={'session_types'}>
          {!showForm && (
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setShowForm(true);
                }}
              >
                Add New Session Type
              </Button>
              <DynamicList
                noHeader
                onRowClick={({ row }) => {
                  setSessionType(row);
                }}
                data={sessionTypesList}
                noDataPlaceholder={'No sessions'}
                isLoading={isLoading}
                columns={[
                  {
                    key: 'name',
                    header: 'Type Name',
                    render: ({ rowData }) => (
                      <SessionTypeListRow
                        {...rowData}
                        onEdit={() => setShowForm(true)}
                        onDelete={() => {
                          deleteSessionType(rowData);
                        }}
                      />
                    ),
                  },
                ]}
              />
            </div>
          )}

          {showForm === true && (
            <SessionTypeForm
              data={sessionType}
              onSave={backToList}
              onCancel={backToList}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SessionType;
