import CodeBlock from '../../components/code-block';

export const HELP_CONTENT_EN = [
  {
    title: 'New account required settings',
    keywords: ['new', 'first time', 'account'],
    content: () => (
      <div>
        Recommend new account settings
        <ul>
          <li>
            Set up a bussines name and website url under &nbsp;
            <b>Account > Bussines</b>
          </li>
          <li>
            Set up a session types &nbsp;
            <b>Account > Session Types</b>
          </li>
          <li>
            Select the days your bussines is open and abailable for sessions
            &nbsp;
            <b>Account > Session Week Days</b>
          </li>
          <li>
            Add plans &nbsp;
            <b>Account > Plans</b>
            &nbsp;make sure plan is active
          </li>
          <li>Add Customers</li>
        </ul>
        <b>Notes:</b>
        <ul>
          <li>
            Customers will not be able to make reservartion until a plan has
            been assign to them
          </li>
          <li>Customers will only see session allowed by their plan</li>
          <li>Customers will only see the allowed reservartion days</li>
          <li>Customers will only see session that have spots available</li>
          <li>
            Customers will only see session if they pland is avtive and still
            has reservation balance
          </li>
        </ul>
      </div>
    ),
  },
  {
    title: 'How to create a session?',
    keywords: ['create', 'add', 'session', 'sessions'],
    content: () => {
      return (
        <div>
          Go to Sessions and click add new session
          <ul>
            <li>Select single event or weekly event if available</li>
            <li>Fillup the form and save</li>
          </ul>
          <b>Notes:</b>
          <ul>
            <li>
              Name can be use to specify in more detail the session in question
              if not entered the default session name will be the session type
            </li>
            <li>
              Number of session is the amount of sessions substracted from the
              customer when the customer attends the session. normally this is
              1.
            </li>
            <li>Spots is the number of customers allowed</li>
          </ul>
        </div>
      );
    },
  },
  {
    title: 'How to add customers?',
    keywords: ['create', 'add', 'customer', 'customers'],
    content: () => (
      <div>
        Go to Customers and click add new customer
        <ul>
          <li>Fillup the form and save</li>
        </ul>
      </div>
    ),
  },
  {
    title: 'How to add instrutors?',
    keywords: ['create', 'add', 'instructor', 'instructors'],
    content: () => (
      <div>
        Go to Instrutors and click add new instrucrtor
        <ul>
          <li>Fillup the form and save</li>
        </ul>
      </div>
    ),
  },
  {
    title: 'What are session types?',
    keywords: ['session', 'type'],
    content: () => (
      <div>
        Session types are the type of clases or sessions you bussines provide,
        this types can be grouped in a plans. <br />
        Example:
        <ul>
          <li>
            Guitar classes can have multiple types like &nbsp;
            <b>Beginner, Intermediate, Advance</b>
          </li>
          <li>
            Dance classes can have multiple types like &nbsp;
            <b>Salsa, K-POP, Classic, etc</b>
          </li>
        </ul>
        Create or add session type in &nbsp; <b>Settings > Session Types</b>
      </div>
    ),
  },
  {
    title: 'What is session week days setting for?',
    keywords: ['session', 'week', 'days'],
    content: () => (
      <div>
        Session week days are the days your bussines provide sessions or classes
        and customers can book sessions. <br />
        Configure your sessions week days in &nbsp;
        <b>Settings > Session Week Days</b>
      </div>
    ),
  },
  {
    title: 'What is forward reservartion days setting for?',
    keywords: ['forward', 'reservartion', 'days'],
    content: () => (
      <div>
        Forward reservartion days setting is for setting up a reservation
        windows for your customer with a start and end day of the week.&nbsp;
        <b>Settings > Forward Reservartion Days</b>
      </div>
    ),
  },
  {
    title: 'What is my customer reservation link?',
    keywords: ['reservation', 'link', 'book', 'customer', 'customers'],
    content: ({ uuid }) => (
      <CodeBlock copyText={`${window.location.origin}/org/${uuid}/reservation`}>
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            window.open(`${window.location.origin}/org/${uuid}/reservation`);
          }}
        >
          {window.location.origin}/org/{uuid}/reservation
        </div>
      </CodeBlock>
    ),
  },

  {
    title: 'How to integrate customer reservation into my site?',
    keywords: ['reservation', 'integrate', 'site', 'customer', 'customers'],
    content: ({ uuid }) => (
      <CodeBlock
        copyText={`<div style="display: flex;justify-content: center;align-items: center;"><iframe src="${window.location.origin}/org/${uuid}/reservation" title="reservation" style="width: 100%;height: 600px;"></iframe></div>`}
      >
        {`<div style="display: flex;justify-content: center;align-items: center;">`}
        <br />
        &nbsp;&nbsp;
        {`
            <iframe src="${window.location.origin}/org/${uuid}/reservation" title="reservation" style="width: 100%;height: 600px;"></iframe>
        `}
        <br />
        {`</div>`}
      </CodeBlock>
    ),
  },

  {
    title: 'How are inactive customers calculated?',
    keywords: ['inactive', 'customer', 'customers'],
    content: ({ uuid }) => (
      <div>
        Inactive customers are customers with no reservations in the last 90
        Days.
      </div>
    ),
  },
];

export const HELP_CONTENT_ES = [];
