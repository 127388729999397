import React, { useState } from 'react';
import { WeekdayOptionTiles } from '../../weekday_option_tiles';
import { useOrgConfig, useUpdateConfig } from '../../../hooks';
import { CONFIG } from '../../../constants/config';
import Button from '@mui/material/Button';

export const AdvanceReservationWindow = () => {
  const updateConfig = useUpdateConfig();
  const orgConfig = useOrgConfig();
  const CONFIGURED_FORWARD_RESERVATION_WINDOW = orgConfig
    ? orgConfig[CONFIG.FORWARD_RESERVATION_WINDOW]
    : null;

  const {
    value: configuredForwardReservationWindowValue = [],
    type: configuredForwardReservationWindowType = 'json',
  } = CONFIGURED_FORWARD_RESERVATION_WINDOW || {};

  const [weekDays, setWeekDays] = useState([]);

  return (
    <div className="ForwardReservationWeekDays">
      <WeekdayOptionTiles
        initialSelectedList={configuredForwardReservationWindowValue}
        useRangeSelection={true}
        onStartSelection={(v) => {
          setWeekDays([]);
        }}
        onChange={(v) => {
          setWeekDays(v);
        }}
      />
      <div className="ctaContainer">
        <Button
          style={{ marginBottom: '10px' }}
          variant="contained"
          disabled={weekDays.length < 1}
          onClick={() => {
            updateConfig({
              name: CONFIG.FORWARD_RESERVATION_WINDOW,
              type: configuredForwardReservationWindowType,
              value: weekDays,
            });
          }}
        >
          Save Window
        </Button>
      </div>
    </div>
  );
};
