import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import './containers.scss';

export const ListContainer = ({ children }) => {
  return (
    <div
      style={{
        maxWidth: '1500px',
        minWidth: '100px',
        width: '100%',
        display: 'flex',
        margin: '0 auto',
        flexDirection: 'column',
      }}
    >
      {children}
    </div>
  );
};

export const ProgressContainer = ({ condition, children }) => {
  return condition ? (
    <div className={'loadingContainer'}>
      <CircularProgress />
    </div>
  ) : (
    children
  );
};
