import React from 'react';
import { useOrgConfig } from '../../../hooks';
export const UserProfile = () => {
  const orgConfig = useOrgConfig();
  const user = orgConfig.user;
  let imageName =
    user && user.firstName
      ? `${user.firstName.substring(0, 1).toUpperCase()}${user.lastName
          .substring(0, 1)
          .toUpperCase()}`
      : '';

  return (
    <div className="user-profile-container">
      <div className="user-round-image">{imageName}</div>
      <div className="user-name">{user && user.firstName}</div>
    </div>
  );
};
