import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { usePickExpApi } from '@pickexp/core';
import {
  ConfirmModal,
  DEFAULT_CONFIRM_MODAL_STATE,
  COMMON_TEXT,
} from '../common';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import { ROUTE } from '../../constants/routes';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import moment from 'moment';
import {
  LocalToUTCFormat,
  UTCToLocalDateDiff,
  UTCToLocalFormat,
  OPTION,
} from '../../utils/date-utils';
import { useNavigate } from 'react-router-dom';
import { sessionDurationOptions } from './constants';
import './style.scss';
// date-fns
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';
import { MobileTimePicker } from '@mui/x-date-pickers';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useOrgConfig } from '../../hooks';

const SessionForm = ({ session, onSave, isEdit }) => {
  const orgConfig = useOrgConfig();
  const [sessionTypesList, setSessionTypesList] = useState([]);
  const [sessionTypesLoaded, setsessionTypesLoaded] = useState(false);
  const [instructorsList, setInstructorsList] = useState([]);

  const weekArray = moment.weekdays();
  const weekDays = weekArray.map((v) => ({
    name: v,
    selected: false,
  }));

  const navigate = useNavigate();
  const api = usePickExpApi().productApi;

  const [sessionDuration, setSessionDuration] = React.useState(
    sessionDurationOptions[0].value
  );

  const initialValues = {
    name: session.name || '',
    instructorUUID: session.instructorUUID || '',
    startDate: UTCToLocalFormat(session.startDate),
    endDate: UTCToLocalFormat(session.endDate),
    description: session.description || '',
    typeId: session.typeId,
    spots: session.spots,
    metadata: {
      weight: session.metadata ? session.metadata.weight : 1,
    },
    weekDays: session.weekDays || '',
    isWeekly: session.weekDays && session.weekDays.length ? true : false,
  };

  if (session.uuid) {
    initialValues.uuid = session.uuid;
  }

  const navigateToSessions = async () => {
    navigate(ROUTE.SESSIONS);
  };

  useEffect(() => {
    const getSessionTypes = async () => {
      const { data } = await api.getSessionTypes();
      setsessionTypesLoaded(true);
      setSessionTypesList(data);
    };

    getSessionTypes();
  }, [api]);

  useEffect(() => {
    const getInstructors = async () => {
      const { data } = await api.getInstructors({});
      setInstructorsList(data);
    };
    orgConfig.canUseInstructors && getInstructors();
  }, [api, orgConfig.canUseInstructors]);

  useEffect(() => {
    const loadSessionDuration = () => {
      if (session.uuid) {
        const currentSessionDuration = UTCToLocalDateDiff(
          session.endDate,
          session.startDate,
          OPTION.SECONDS
        );
        setSessionDuration(currentSessionDuration);
      }
    };

    loadSessionDuration();
  }, [session.endDate, session.startDate, session.uuid]);

  const navigateBack = async () => {
    navigate(ROUTE.SESSIONS);
  };

  const validateForm = () => {
    const errors = {};
    return errors;
  };

  const addSession = async (values) => {
    const { data } = await api.createOrUpdateSession(values);
    onSave && onSave();
    return data.status === 'Success';
  };
  const [openConfirmModal, setOpenConfirmModal] = useState(
    DEFAULT_CONFIRM_MODAL_STATE
  );

  const onFormSubmitHandler = async (values, { setSubmitting }) => {
    setOpenConfirmModal({
      isOpen: true,
      onAcceptCallback: async () => {
        const sendValues = {
          ...values,
          startDate: LocalToUTCFormat(values.startDate),
          endDate: LocalToUTCFormat(values.endDate),
        };
        await addSession(sendValues);
        setSubmitting(false);
        navigateToSessions();
      },
      onRejectCallback: () => setOpenConfirmModal({ isOpen: false }),
    });
  };

  return (
    <div>
      {sessionTypesLoaded && sessionTypesList.length === 0 && (
        <div>No session types available, please add session types firs</div>
      )}
      {sessionTypesLoaded && sessionTypesList.length && (
        <Formik
          initialValues={initialValues}
          validate={validateForm}
          onSubmit={onFormSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => {
            const isDateRangeValid =
              UTCToLocalDateDiff(values.endDate, values.startDate) > 0;

            const isSaveDisabled =
              isSubmitting ||
              values.spots === undefined ||
              values.spots <= 0 ||
              values.typeId === undefined ||
              !isDateRangeValid;

            const updateDateRange = ({ startDate, duration }) => {
              setFieldValue('startDate', startDate);
              if (duration) {
                setSessionDuration(duration);
                const d = moment(startDate);
                d.add('s', duration);
                setFieldValue('endDate', d.toDate());
              }
            };

            return (
              <form onSubmit={handleSubmit}>
                <div className="sessionForm">
                  {isEdit && (
                    <FormControl fullWidth>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.isWeekly}
                            onChange={(event) => {
                              setFieldValue('isWeekly', event.target.checked);
                            }}
                            name="gilad"
                          />
                        }
                        label="Is a weekly session"
                      />
                    </FormControl>
                  )}
                  <input
                    type="text"
                    name="name"
                    onChange={handleChange}
                    placeholder={'Name'}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                  <input
                    type="text"
                    name="description"
                    placeholder={'Description'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                  />

                  {orgConfig.canUseInstructors &&
                    instructorsList.length === 0 && (
                      <div className="add-instructor-message">
                        Add your first instructor
                        <b
                          onClick={() => {
                            navigate(ROUTE.INSTRUCTORS);
                          }}
                        >
                          {' '}
                          here
                        </b>{' '}
                        (Optional)
                      </div>
                    )}
                  {instructorsList.length > 0 && (
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Instructor Name
                      </InputLabel>

                      <Select
                        labelId="instructor-name-select-label"
                        id="instructor-name-select"
                        value={values.instructorUUID}
                        onChange={(newValue) => {
                          setFieldValue(
                            'instructorUUID',
                            newValue.target.value
                          );
                        }}
                      >
                        {instructorsList.map((option, index) => (
                          <MenuItem
                            value={option.uuid}
                            key={`instructor-list-item-${index}`}
                          >
                            {option.firstName} {option.lastName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}

                  <input
                    type="number"
                    name="spots"
                    placeholder={'Spots'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.spots}
                  />

                  <TextField
                    id="number-of-sessions"
                    label="Number of sessions"
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    value={values.metadata.weight}
                    onChange={(v) => {
                      !isNaN(v.target.value) &&
                        setFieldValue('metadata', {
                          ...values.metadata,
                          weight: Number(v.target.value),
                        });
                    }}
                  />

                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Session Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="session-type-select"
                      value={values.typeId}
                      onChange={(newValue) => {
                        setFieldValue('typeId', newValue.target.value);
                      }}
                    >
                      {sessionTypesList.map((option, index) => (
                        <MenuItem
                          value={option.uuid}
                          key={`menu-list-item-${index}`}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {isEdit && values.isWeekly && (
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Weekdays
                      </InputLabel>

                      <Select
                        labelId="instructor-name-select-label"
                        id="instructor-weekdays-select"
                        value={values.weekDays.split(',')}
                        onChange={(newValue) => {
                          setFieldValue(
                            'weekDays',
                            newValue.target.value.toString()
                          );
                        }}
                        multiple
                      >
                        {weekDays.map((option, index) => (
                          <MenuItem
                            value={option.name}
                            key={`instructor-list-item-${index}`}
                          >
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}

                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    {values.isWeekly ? (
                      <TimePicker
                        label="Start Time"
                        value={values.startDate}
                        onChange={(newValue) => {
                          updateDateRange({
                            startDate: newValue.toDate(),
                            endDate: sessionDuration,
                          });
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    ) : (
                      <div className="startDateContainer">
                        <DatePicker
                          label="Session Date"
                          value={values.startDate}
                          onChange={(newValue) => {
                            updateDateRange({
                              startDate: newValue,
                              duration: sessionDuration,
                            });
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                        <Stack spacing={3}>
                          <MobileTimePicker
                            label="Session Time"
                            value={values.startDate}
                            onChange={(newValue) => {
                              updateDateRange({
                                startDate: newValue,
                                duration: sessionDuration,
                              });
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </div>
                    )}
                  </LocalizationProvider>

                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label2">
                      Session Duration
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label2"
                      id="session-type-select2"
                      value={sessionDuration}
                      onChange={(newValue) => {
                        setSessionDuration(newValue.target.value);
                        updateDateRange({
                          startDate: values.startDate,
                          duration: newValue.target.value,
                        });
                      }}
                    >
                      {sessionDurationOptions.map((option, index) => (
                        <MenuItem
                          value={option.value}
                          key={`session-duration-option-item-${index}`}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <div className={'feedbackContainer'}></div>

                  <div className={'actionContainer'}>
                    <div className={'actionbuttons'}>
                      <Button
                        variant="contained"
                        onClick={navigateBack}
                        startIcon={<ArrowBackIcon />}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={isSaveDisabled}
                        startIcon={<SaveIcon />}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
                <ConfirmModal
                  isOpen={openConfirmModal.isOpen}
                  bodyText={
                    session.uuid
                      ? `Do you want create session ${values.name}?`
                      : `Do you want to update session ${values.name}?`
                  }
                  onAccept={openConfirmModal.onAcceptCallback}
                  acceptText={COMMON_TEXT.YES}
                  onReject={openConfirmModal.onRejectCallback}
                  rejectText={COMMON_TEXT.NO}
                />
              </form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default SessionForm;
