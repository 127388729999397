import React, { useState, useCallback, useEffect } from 'react';
import { SectionHeader } from '../common';
import SessionForm from './session-form';
import './style.scss';
import { ROUTE } from '../../constants/routes';
import { useNavigate, useParams } from 'react-router-dom';
import { usePickExpApi } from '@pickexp/core';

const EditSession = () => {
  const { SESSION_UUID } = useParams();
  const [session, setSession] = useState();
  const [hasLoaded, setHasLoaded] = useState(false);

  let navigate = useNavigate();

  const onBackHandler = () => {
    navigate(ROUTE.SESSIONS);
  };

  const api = usePickExpApi().productApi;
  const getSession = useCallback(async () => {
    const { data } = await api.getSessionsByUUID({ uuid: SESSION_UUID });
    setHasLoaded(true);
    setSession(data);
  }, [api, SESSION_UUID]);

  useEffect(() => {
    !hasLoaded && getSession();
  }, [hasLoaded, getSession]);

  return (
    <div>
      <SectionHeader onBack={onBackHandler} title={'Edit Session'} />

      <div className="new-session-flow">
        {session && (
          <SessionForm session={session} onSave={onBackHandler} isEdit={true} />
        )}
      </div>
    </div>
  );
};

export default EditSession;
