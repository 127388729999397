import React, { useState, useRef, useEffect, useCallback } from 'react';
import Routes from './Routes';
import { useLocation } from 'react-router-dom';
import { usePickExpApi } from '@pickexp/core';
import { AppContext, DEFAULT_APP_STATE } from './hooks';
import { CustomizedSnackbars, ALERT_SEVERITY } from './components/alerts';

function App() {
  const appRef = useRef(null);
  const [appState, setAppState] = useState(DEFAULT_APP_STATE);
  const [hasListener, setHasListener] = useState(false);
  const [appAlert, setAppAlert] = useState({
    isOpen: false,
  });
  const location = useLocation();
  const api = usePickExpApi();
  const stk =
    location.search &&
    location.search.includes('stk=') &&
    location.search.split('stk=')[1];

  if (stk) {
    api.getCoreApi.checkIn(stk);
  }

  const showAppAlert = useCallback(
    (props) => {
      setAppAlert({
        ...appAlert,
        isOpen: true,
        ...props,
      });
    },
    [appAlert]
  );

  const closeCustomerSession = useCallback(
    (message) => {
      sessionStorage.removeItem('cstk');
      localStorage.removeItem('stk');
      localStorage.removeItem('ptk');
      showAppAlert({
        autoHideDuration: 2000,
        severity: ALERT_SEVERITY.ERROR,
        message: message,
        onClose: () => {
          window.location.reload();
        },
      });
    },
    [showAppAlert]
  );

  const handleAppCustomEventListener = useCallback(
    (v) => {
      if (v && v.detail && v.detail.message) {
        if (v.detail.message === 'session closed') {
          closeCustomerSession('Sesión cerrada');
        }

        if (v.detail.message === 'session expired') {
          closeCustomerSession('La sesión expiró iniciar sesión nuevamente');
        }

        if (v.detail.message === 'network error') {
          showAppAlert({
            autoHideDuration: 2000,
            severity: ALERT_SEVERITY.ERROR,
            message: 'Error de red inténtalo de nuevo',
          });
        }
      }
    },
    [closeCustomerSession, showAppAlert]
  );

  useEffect(() => {
    if (!hasListener && window) {
      window.addEventListener('applistener', handleAppCustomEventListener);
      setHasListener(true);
    }
  }, [hasListener, handleAppCustomEventListener]);

  return (
    <div className="App" ref={appRef}>
      <AppContext.Provider
        value={{
          appState,
          setAppState,
          appAlert,
          showErrorAlert: (props) => {
            showAppAlert({
              ...props,
              severity: ALERT_SEVERITY.ERROR,
            });
          },
          showInfoAlert: (props) => {
            showAppAlert({
              ...props,
              severity: ALERT_SEVERITY.INFO,
            });
          },
          showSuccessAlert: (props) => {
            showAppAlert({
              ...props,
              severity: ALERT_SEVERITY.SUCCESS,
            });
          },
          showWarningAlert: (props) => {
            showAppAlert({
              ...props,
              severity: ALERT_SEVERITY.WARNING,
            });
          },
        }}
      >
        <Routes />
      </AppContext.Provider>
      <CustomizedSnackbars
        {...appAlert}
        onClose={(v) => {
          appAlert.onClose && appAlert.onClose(v);
          setAppAlert({ ...appAlert, isOpen: false });
        }}
      />
      <div className="version">
        {`v${process.env.REACT_APP_VERSION}` || 'v0.0.0'}
      </div>
    </div>
  );
}

export default App;
