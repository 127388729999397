import React, { useState, useEffect } from 'react';
import { useOrgConfig, useUpdateConfig } from '../../../hooks';
import { CONFIG, MIN_RESERVATION_TIME_LIST } from '../../../constants/config';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import './style.scss';

export const MinReservationTime = () => {
  const [selectedTime, setSelectedTime] = useState();
  const updateConfig = useUpdateConfig();
  const orgConfig = useOrgConfig();

  useEffect(() => {
    setSelectedTime({
      label: '120 Minutes',
      value: orgConfig[CONFIG.MIN_RESERVATION_MINUTES]
        ? orgConfig[CONFIG.MIN_RESERVATION_MINUTES].value
        : '',
    });
  }, [orgConfig]);

  return (
    <div className="MinReservationTimeContainer">
      {selectedTime && (
        <FormControl fullWidth>
          <Select
            className="min-reservation-time-select"
            id="min-reservation-time-select"
            value={selectedTime.value}
            onChange={(newValue) => {
              const v = MIN_RESERVATION_TIME_LIST.find(
                (item) => item.value === newValue.target.value
              );
              setSelectedTime(v);
            }}
          >
            {MIN_RESERVATION_TIME_LIST.map((option, index) => (
              <MenuItem value={option.value} key={`min-r-list-item-${index}`}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <div className="ctaContainer">
        <Button
          style={{ marginBottom: '10px' }}
          variant="contained"
          disabled={false}
          onClick={() => {
            updateConfig({
              type: 'json',
              value: selectedTime.value,
              name: CONFIG.MIN_RESERVATION_MINUTES,
            });
          }}
        >
          Save Week Days
        </Button>
      </div>
    </div>
  );
};
