import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '../../../hooks';
import './style.scss';

export const DynamicList = ({
  data,
  columns,
  onRowClick,
  isLoading,
  noDataPlaceholder,
  noHeader,
  enableSelectedRow,
}) => {
  const theme = useTheme();

  const commonColStyle = { style: { flex: '1' } };

  const ItemColumn = ({ children }) => (
    <div {...commonColStyle}>{children}</div>
  );
  const [selectedIndex, setSelectedIndex] = useState();
  const noData = data && data.length > 0 ? false : true;

  let getRowStyle = (index) => {
    const st = {
      height: 'auto',
    };

    if (enableSelectedRow) {
      st.cursor = 'pointer';
    }

    if (
      enableSelectedRow &&
      selectedIndex !== undefined &&
      selectedIndex === index
    ) {
      st.backgroundColor = theme.SECUNDARY_BACKGROUND_COLOR;
      st.color = theme.SECUNDARY_TEXT_COLOR;
    }

    return st;
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            margin: '50px',
          }}
        >
          <CircularProgress />
        </div>
      ) : noData ? (
        <div
          className={'noDataContainer'}
          style={{
            height: '100px',
            justifyContent: 'center',
            display: 'flex',
            fontSize: '25px',
            alignItems: 'center',
          }}
        >
          <div>{noDataPlaceholder || 'Not Data Available'}</div>
        </div>
      ) : (
        <div
          className={'dynamic-list-item-row'}
          style={{
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          {!noHeader && (
            <div
              ey={'list-item-headers'}
              style={{
                margin: '10px 20px',
                fontWeight: 'bold',
                fontSize: '25px',
                height: '80px',
              }}
            >
              {columns.map((column, index) => (
                <ItemColumn key={`header-col-${index}`}>
                  {column.header}
                </ItemColumn>
              ))}
            </div>
          )}

          {data.map((record, recordIndex) => (
            <div
              key={`list-item-${recordIndex}`}
              style={getRowStyle(recordIndex)}
              onClick={() => {
                enableSelectedRow && setSelectedIndex(recordIndex);
                onRowClick && onRowClick({ row: record, index: recordIndex });
              }}
            >
              {columns.map((column, colIndex) => (
                <ItemColumn key={`col-i-${colIndex}`}>
                  {column.render({
                    value: record[column.key],
                    index: recordIndex,
                    colIndex: colIndex,
                    rowData: record,
                  })}
                </ItemColumn>
              ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
};
