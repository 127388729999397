import React from 'react';
import { ActionSection } from '../common';
import { ROUTE, ROUTE_PARAM, getRoutePath } from '../../constants/routes';
import { useNavigate } from 'react-router-dom';
const InstructorListRow = ({ uuid, firstName, isSelected, onDelete }) => {
  let navigate = useNavigate();
  const onEdit = () => {
    navigate(
      getRoutePath(ROUTE.EDIT_INSTRUCTOR, { [ROUTE_PARAM.INSTRUCTOR_ID]: uuid })
    );
  };

  return (
    <div className={'instrutor-list-row'}>
      <div className={'instrutor-list-data'}>
        <div>{firstName}</div>
      </div>
      <ActionSection
        canEdit={true}
        onEdit={onEdit}
        canDelete
        onDelete={() => {
          onDelete && onDelete({ uuid, firstName });
        }}
        isWhite
      />
    </div>
  );
};

export default InstructorListRow;
