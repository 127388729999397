import React from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import './style.scss';

const CodeBlock = ({ children, onClick, copyText, actionOnTop }) => {
  return (
    <div className={'code-block'} onClick={onClick}>
      <div className="top-action-bar">
        {actionOnTop && copyText && (
          <div
            className="copy"
            onClick={() => {
              navigator.clipboard.writeText(copyText);
            }}
          >
            <ContentCopyIcon />
          </div>
        )}
      </div>
      <div className="block-row">
        {children}
        <div className="right-action-bar">
          {!actionOnTop && copyText && (
            <div
              className="copy"
              onClick={() => {
                navigator.clipboard.writeText(copyText);
              }}
            >
              <ContentCopyIcon />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CodeBlock;
