import React, { useState, useCallback, useEffect } from 'react';
import { SectionHeader, ProgressContainer } from '../common';

import SvgIcon from '@mui/material/SvgIcon';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import EventIcon from '@mui/icons-material/Event';
import Button from '@mui/material/Button';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import SessionForm from './session-form';
import { STEPS, STEP } from './constants';
import { CONFIG } from '../../constants/config';
import './style.scss';
import { ROUTE, ROUTE_PARAM } from '../../constants/routes';
import { useNavigate } from 'react-router-dom';
import { WeekdayOptionTiles } from '../weekday_option_tiles';
import { useOrgConfig } from '../../hooks';
import { usePickExpApi } from '@pickexp/core';

const CreateSession = ({ session, onSave }) => {
  let navigate = useNavigate();
  const orgConfig = useOrgConfig();
  const api = usePickExpApi().productApi;
  const CONFIGURED_WEEK_DAYS = orgConfig
    ? orgConfig[CONFIG.SESSIONS_WEEK_DAYS]
    : null;

  const weekArray = moment.weekdays();
  const [weekDays, setWeekDays] = useState(
    weekArray.map((v) => ({
      name: v,
      selected: false,
    }))
  );

  const [step, setStep] = useState(STEPS);
  const [hasSessionType, setHasSessionType] = useState(false);
  const [loading, setLoading] = useState(true);

  const nextStep = (value) => {
    STEPS[value] && setStep(STEPS[value]);
  };
  const onBackHandler = () => {
    navigate(ROUTE.SESSIONS);
  };

  const getSessionTypes = useCallback(async () => {
    const { data } = await api.getSessionTypes();
    if (data.length > 0) {
      setHasSessionType(true);
    }
    setLoading(false);
  }, [api]);

  useEffect(() => {
    !hasSessionType && getSessionTypes();
  }, [api, getSessionTypes, hasSessionType]);

  return (
    <div>
      <SectionHeader onClick={onBackHandler} title={step.title} />

      <ProgressContainer condition={loading}>
        <div className="new-session-flow">
          <div className="create-session-type-messages">
            {!hasSessionType && (
              <>
                <div>
                  You need to create at least one session type before crearting
                  a session,
                </div>
                <b
                  onClick={() => {
                    navigate(
                      ROUTE.SETTINGS.replace(
                        ROUTE_PARAM.SETTING_NAME,
                        'session_types'
                      )
                    );
                  }}
                >
                  &nbsp;Clik here to go to session types view
                </b>
                .
              </>
            )}

            {orgConfig.canUseWeeklyEvents && !CONFIGURED_WEEK_DAYS && (
              <>
                <div>
                  You need to configure your weekdays first before using weekly
                  events,
                </div>
                <b
                  onClick={() => {
                    navigate(
                      ROUTE.SETTINGS.replace(
                        ROUTE_PARAM.SETTING_NAME,
                        'sessions_week_days'
                      )
                    );
                  }}
                >
                  &nbsp;Click here.
                </b>
              </>
            )}
          </div>

          {step.title === STEPS.title && hasSessionType && (
            <div className="type_option_tiles">
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  '& > :not(style)': {
                    m: 5,
                    width: 250,
                    height: 250,
                  },
                }}
              >
                <Paper
                  elevation={3}
                  onClick={() => {
                    nextStep(STEP.COMPLETE_FORM);
                  }}
                >
                  <SvgIcon component={EventIcon} inheritViewBox />
                  <div>Single Event</div>
                </Paper>

                {orgConfig.canUseWeeklyEvents && CONFIGURED_WEEK_DAYS && (
                  <Paper
                    elevation={3}
                    onClick={() => {
                      nextStep(STEP.WEEKLY_EVENT);
                    }}
                  >
                    <SvgIcon component={EventRepeatIcon} inheritViewBox />
                    <div>Weekly Event</div>
                  </Paper>
                )}
              </Box>
            </div>
          )}

          {step.title === STEPS[STEP.WEEKLY_EVENT].title && (
            <div className="weekly-option-selection">
              <WeekdayOptionTiles
                allowList={CONFIGURED_WEEK_DAYS.value}
                onChange={(v) => {
                  setWeekDays(v);
                }}
              />
              <Button
                variant="contained"
                onClick={() => {
                  nextStep(STEP.COMPLETE_FORM);
                }}
                disabled={!weekDays.some((v) => v.selected)}
              >
                Continue
              </Button>
            </div>
          )}

          {step.title === STEPS[STEP.COMPLETE_FORM].title && (
            <SessionForm
              session={{
                ...session,
                weekDays: weekDays
                  .filter((v) => v.selected)
                  .map((v) => v.name)
                  .toString(),
              }}
              onSave={onSave}
            />
          )}
        </div>
      </ProgressContainer>
    </div>
  );
};

export default CreateSession;
