import React, { useState } from 'react';
import { usePickExpApi } from '@pickexp/core';
import { SessionTabs } from '../../components/SessionTabs';
import {
  getDateUTCRange,
  getCurrentUTCDate,
  LocalToUTCFormat,
  formattedDate,
  DATE_FORMAT,
} from '../../utils/date-utils';
import './style.scss';
import { useAppState } from '../../hooks';
import { v4 as uuidv4 } from 'uuid';

const ReservationSessions = (props) => {
  const {
    resetView,
    handleFindSession,
    loading,
    noFoundPlaceholder,
    setOpenConfirmModal,
    overridePublicOrgConfigValue,
    bookableSessions,
    customerEmail,
    businessUUID,
    dateRange,
    setDateRange,
  } = props;

  const [currentActiveTabIndex, setCurrentActiveTabIndex] = useState(0);
  const { showErrorAlert, showSuccessAlert } = useAppState();
  const [deviceSessionUUID] = useState(uuidv4());
  const api = usePickExpApi().productApi;
  const reserveSessionHandler = async ({ uuid, startDate, endDate }) => {
    setOpenConfirmModal({
      isOpen: true,
      onAcceptCallback: async () => {
        const result = await api.createReservation({
          email: customerEmail,
          orgUUID: businessUUID,
          sessionUUID: uuid,
          startDate: LocalToUTCFormat(startDate),
          endDate: LocalToUTCFormat(endDate),
          feStartDate: formattedDate(startDate, DATE_FORMAT.FE_DATE),
          feEndDate: formattedDate(endDate, DATE_FORMAT.FE_DATE),
          deviceSessionUUID,
        });

        if (result && result.data) {
          if (result.data.status === 'Success') {
            showSuccessAlert({ message: 'Reservacion exitosa.' });
            handleFindSession({ date: dateRange });
          } else {
            const errorMessage = result.data.message;

            if (errorMessage.includes('plan maximum reservations')) {
              showErrorAlert({
                message: 'Su plan no tiene mas reservaciones disponibles.',
              });
              resetView();
              setCurrentActiveTabIndex(0);
            } else if (errorMessage.includes('Plan expired on this date')) {
              showErrorAlert({
                message: 'Su plan estará expirado en esta fecha',
              });
            } else if (
              errorMessage.includes('Customer can not reserv plan expired')
            ) {
              showErrorAlert({
                message: 'Su plan ha expirado',
              });
              resetView();
              setCurrentActiveTabIndex(0);
            } else {
              showErrorAlert({
                message:
                  'No puede hacer la reservation en este moment intende de nuevo',
              });
            }
          }
        } else {
          showErrorAlert({
            message: 'Algo salio mal intende de nuevo mas tarde',
          });
        }

        setOpenConfirmModal({ isOpen: false });
      },
      onRejectCallback: () => setOpenConfirmModal({ isOpen: false }),
    });
  };

  const tabChangeHandler = ({ formatedDate }, index) => {
    const newRange = getDateUTCRange(formatedDate);
    if (index === 0) {
      newRange.startDate = getCurrentUTCDate();
    }
    setDateRange(newRange);
    setCurrentActiveTabIndex(index);
    handleFindSession({ date: newRange });
  };

  const rowClickHandler = ({ row }) => {};

  return (
    <>
      <SessionTabs
        data={bookableSessions}
        loading={loading}
        noFoundPlaceholder={noFoundPlaceholder}
        onTabChange={tabChangeHandler}
        onRowClick={rowClickHandler}
        onReserve={reserveSessionHandler}
        currentActiveTabIndex={currentActiveTabIndex}
        overridePublicOrgConfigValue={overridePublicOrgConfigValue}
      />
    </>
  );
};

export default ReservationSessions;
