import './simple-cell.scss';

export const SimpleCell = ({ label, value, style = {} }) => {
  return (
    <div className="simple-cell" style={style}>
      {label && <div className="cell-label">{label}</div>}
      <div className="cell-value">{value}</div>
    </div>
  );
};
