import React from 'react';
import { Formik } from 'formik';
import { usePickExpApi } from '@pickexp/core';
import Button from '@mui/material/Button';
import { useAppState } from '../../../hooks';
const SessionTypeForm = ({ data, onSave, onCancel }) => {
  const { showErrorAlert, showSuccessAlert } = useAppState();
  const initialValues = { name: data.name || '' };
  const api = usePickExpApi().productApi;
  if (data.uuid) {
    initialValues.uuid = data.uuid;
  }

  const validateForm = (values) => {
    const errors = {};
    return errors;
  };

  const createOrUpdateSessionType = async (values) => {
    const { data } = await api.createOrUpdateSessionType(values);
    onSave && onSave(data);
    return data.status === 'Success';
  };

  const onFormSubmitHandler = async (values, { setSubmitting }) => {
    const r = await createOrUpdateSessionType(values);
    setSubmitting(false);
    if (r) {
      showSuccessAlert({
        message: 'Saved',
      });
    } else {
      showErrorAlert({
        message: 'Error',
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={onFormSubmitHandler}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          <input
            type="name"
            name="name"
            placeholder="Session Type Name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
          />

          <Button
            variant="contained"
            color="grey"
            type="button"
            onClick={() => {
              onCancel && onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitting || !values.name}
            type="submit"
          >
            Save
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default SessionTypeForm;
