import React, { useState } from 'react';
import { usePickExpApi } from '@pickexp/core';
import Button from '@mui/material/Button';
import { useParams } from 'react-router-dom';
import {
  UTCToLocalFormat,
  SPANISH_WEEKDAY_NAME,
  SPANISH_MONTHS_NAME,
} from '../../utils/date-utils';
import './style.scss';
import { ProgressContainer } from '../../components/common';

const ReservationBooked = (props) => {
  const {
    getCustomerReservedSessions,
    reservedSessions,
    loadingReservedSessions,
  } = props;
  const { ORG_ID } = useParams();

  const api = usePickExpApi().productApi;

  const publicCustomerCancelSession = async ({
    sessionActivityUUID,
    customerUUID,
  }) => {
    await api.publicCustomerCancelSession({
      orgUUID: ORG_ID,
      customerUUID: customerUUID,
      sessionActivityUUID,
    });
    getCustomerReservedSessions();
  };

  return (
    <>
      <div className="reservedSessionsContainer">
        <ProgressContainer
          condition={loadingReservedSessions}
        ></ProgressContainer>
        {!loadingReservedSessions &&
          reservedSessions &&
          reservedSessions.reservations.length === 0 && (
            <h3>No tiene reservaciones</h3>
          )}
        {reservedSessions &&
          reservedSessions.reservations &&
          !loadingReservedSessions &&
          reservedSessions.reservations.map((s) => {
            let localStartDate = UTCToLocalFormat(s.startDate, 'LLLL');

            try {
              const splitedStartDate = localStartDate.split(',');

              const weekDayK = splitedStartDate[0];
              const monthK = splitedStartDate[1].trim().split(' ')[0];

              const spanishWeekDayName = SPANISH_WEEKDAY_NAME[weekDayK];
              const spanishMonthName = SPANISH_MONTHS_NAME[monthK];

              if (spanishWeekDayName && spanishMonthName) {
                localStartDate = localStartDate.replace(
                  weekDayK,
                  spanishWeekDayName
                );
                localStartDate = localStartDate.replace(
                  monthK,
                  spanishMonthName
                );
              }
            } catch (e) {
              console.log(e);
            }

            return (
              <div className="reservedSession">
                <div className="sessionTime">{localStartDate}</div>
                <div className="sessionName">{s.sessionName}</div>
                <div className="sessionInstructor">{s.instructorName}</div>
                <div className="sessionCancelButton">
                  {s.canCancel && (
                    <Button
                      onClick={() => {
                        publicCustomerCancelSession({
                          sessionActivityUUID: s.sessionActivityUUID,
                          customerUUID: reservedSessions.customerUUID,
                        });
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default ReservationBooked;
