import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { isEmailValid } from '../../utils/validations';
import { usePickExpApi } from '@pickexp/core';
import CircularProgress from '@mui/material/CircularProgress';

import './style.scss';

const ReservationLogin = (props) => {
  const [loading, setLoading] = useState(false);
  const api = usePickExpApi().productApi;
  const { handleFindSession, customerEmail, setCustomerEmail, orgUUID } = props;

  const attemptLogin = async (el) => {
    setLoading(true);
    window.localStorage.removeItem('stk');
    window.localStorage.removeItem('ptk');
    window.sessionStorage.removeItem('cstk');
    try {
      await api.publicCustomerLogin({
        email: customerEmail,
        orgUUID: orgUUID,
      });
      setLoading(false);
      setTimeout(function () {
        handleFindSession(el);
      }, 500);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className={'rowSeccion'}>
      <div className={'findInputContainer'}>
        <TextField
          required
          id="email"
          label="Email"
          variant="outlined"
          onChange={(value) => {
            setCustomerEmail(value.target.value);
          }}
          value={customerEmail}
        />
        <Button
          onClick={attemptLogin}
          className={'findButton'}
          variant="contained"
          endIcon={<SendIcon />}
          type="button"
          disabled={loading || !isEmailValid(customerEmail)}
        >
          Buscar clase
        </Button>
      </div>
      <div className="loading-spiner">{loading && <CircularProgress />}</div>
    </div>
  );
};

export default ReservationLogin;
