import React, { forwardRef } from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const ALERT_SEVERITY = {
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
  INFO: 'info',
};

export const CustomizedSnackbars = ({
  isOpen,
  onClose,
  severity,
  message,
  vertical,
  horizontal,
  autoHideDuration,
}) => {
  const handleClose = (event, reason) => {
    onClose && onClose({ reason });
    if (reason === 'clickaway') {
      return;
    }
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        open={isOpen}
        autoHideDuration={autoHideDuration || 6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: vertical || 'bottom',
          horizontal: horizontal || 'center',
        }}
      >
        <Alert
          onClose={handleClose}
          severity={severity || 'info'}
          sx={{ width: '100%' }}
        >
          {message || ''}
        </Alert>
      </Snackbar>
    </Stack>
  );
};
