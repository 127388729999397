import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { usePickExpApi } from '@pickexp/core';
import './view-customer.scss';
import { NavBar, BackButton } from '../nav';
import { ROUTE } from '../../constants/routes';
import { CancelReservation, CustomModal } from '../common';

import { UpcomingActivitiesRow } from './upcoming-activities-row';
import { HistoryRecordRow } from './history-record-row';
import { CustomerOptionsMenu } from './customer-options-menu';
import { CustomerPlanAdvanceOptionForm } from './advance-option';

const ViewCustomer = ({ activeTabName }) => {
  const { CUSTOMER_ID } = useParams();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [customer, setCustomer] = useState();
  const [openCancelSession, setOpenCancelSession] = useState({
    isOpen: false,
  });

  const [isAdvanceOptionModalPlan, setIsAdvanceOptionModalPlan] = useState();

  const api = usePickExpApi().productApi;

  const hasUpcomingActivities =
    customer &&
    customer.upcomingActivities &&
    customer.upcomingActivities.length > 0
      ? true
      : false;

  const getCustomer = useCallback(async () => {
    const { data } = await api.getCustomer({
      uuid: CUSTOMER_ID,
      withHistory: true,
      withUpcomingActivities: true,
      withEmail: true,
    });
    setHasLoaded(true);
    setCustomer(data);
  }, [api, CUSTOMER_ID]);

  useEffect(() => {
    !hasLoaded && getCustomer();
  }, [hasLoaded, getCustomer]);

  return (
    <div className="view-customer-container">
      <NavBar activeTabName={activeTabName} />
      {hasLoaded && customer && (
        <div className="inner-content-container">
          <div className="customerInfoContainer">
            <BackButton path={`${ROUTE.CUSTOMERS}`} />
            <div className="customerNameInfo">
              <h2>
                {customer.firstName}
                <CustomerOptionsMenu
                  customer={customer}
                  handleReload={() => {
                    getCustomer();
                  }}
                />
              </h2>
            </div>
            <div></div>
          </div>

          {hasUpcomingActivities && (
            <div className="customerUpcomingActivities">
              <div className="title-tag">Upcoming Sessions</div>
              <div className="upcomingActivitiesList">
                {customer.upcomingActivities.map((activity, index) => (
                  <UpcomingActivitiesRow
                    activity={activity}
                    index={index}
                    key={`UpcomingActivitiesRowK${index}`}
                    onClickCancel={() => {
                      setOpenCancelSession({
                        isOpen: true,
                        customerUUID: CUSTOMER_ID,
                        sessionUUID: activity.sessionUUID,
                        sessionActivityUUID: activity.sessionActivityUUID,
                        isRefund: true,
                        cb: ({ accepted }) => {
                          setOpenCancelSession({ isOpen: false });
                          accepted && getCustomer();
                        },
                      });
                    }}
                  />
                ))}
              </div>
            </div>
          )}

          <div className="customerHistory">
            <div className="title-tag">Plans History</div>
            {customer.history &&
              customer.history.map((record, index) => (
                <HistoryRecordRow
                  record={record}
                  index={index}
                  key={`HistoryRecordRowK${index}`}
                  onOpenAdvanceOptionModal={({ plan, isAdd }) => {
                    setIsAdvanceOptionModalPlan({ plan, isAdd });
                  }}
                />
              ))}
          </div>
        </div>
      )}
      <CancelReservation {...openCancelSession} />
      <CustomModal
        isOpen={isAdvanceOptionModalPlan ? true : false}
        onClose={() => {
          setIsAdvanceOptionModalPlan(null);
        }}
      >
        <CustomerPlanAdvanceOptionForm
          plan={isAdvanceOptionModalPlan && isAdvanceOptionModalPlan.plan}
          isAdd={isAdvanceOptionModalPlan && isAdvanceOptionModalPlan.isAdd}
          customerUUID={CUSTOMER_ID}
          onClose={() => {
            setIsAdvanceOptionModalPlan(null);
          }}
          onSave={() => {
            setIsAdvanceOptionModalPlan(null);
            getCustomer();
          }}
        />
      </CustomModal>
    </div>
  );
};

export default ViewCustomer;
