import React, { useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Divider from '@mui/material/Divider';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { PurchasePlanModal } from './purchase-plan-modal';
import { UTCToCurrentDiff } from '../../utils/date-utils';
import { useNavigate } from 'react-router-dom';
import { ROUTE, ROUTE_PARAM, getRoutePath } from '../../constants/routes';
import {
  DEFAULT_CONFIRM_MODAL_STATE,
  ConfirmModal,
  COMMON_TEXT,
} from '../common';
import { usePickExpApi } from '@pickexp/core';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const CustomerOptionsMenu = ({ customer, handleReload }) => {
  const [anchorEl, setAnchorEl] = useState();
  const [showEmail, setShowEmail] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(
    DEFAULT_CONFIRM_MODAL_STATE
  );
  const api = usePickExpApi().productApi;
  const [isPurchasePlanModalOpen, setIsPurchasePlanModalOpen] = useState(false);
  let navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { history, uuid: customerUUID, firstName, email } = customer;

  const currentPlan = history.find(
    (r) => UTCToCurrentDiff(r.startDate).days < 0
  );

  const handleOnEdit = ({ customerUUID }) => {
    navigate(
      getRoutePath(ROUTE.EDIT_CUSTOMER, {
        [ROUTE_PARAM.CUSTOMER_ID]: customerUUID,
      })
    );
  };

  const handleOnDelete = async ({ customerUUID, firstName }) => {
    setOpenConfirmModal({
      text: `Seguro que desea eliminar el cliente ${firstName}?`,
      isOpen: true,
      onAcceptCallback: async () => {
        await api.deleteCustomer({ uuid: customerUUID });
        setOpenConfirmModal({ isOpen: false });
        navigate(getRoutePath(ROUTE.CUSTOMERS));
      },
      onRejectCallback: () => {
        setOpenConfirmModal({ isOpen: false });
      },
    });
  };

  return (
    <div>
      <MoreVertIcon id="demo-customized-button" onClick={handleClick} />
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            setShowEmail(!showEmail);
          }}
          disableRipple
        >
          {showEmail ? <VisibilityIcon /> : <VisibilityOffIcon />}
          {showEmail ? `Hide (${email})` : 'Show email'}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOnEdit({ customerUUID });
            handleClose();
          }}
          disableRipple
        >
          <EditIcon />
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOnDelete({ customerUUID, firstName });
            handleClose();
          }}
          disableRipple
        >
          <DeleteForeverIcon />
          Delete
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={() => {
            setIsPurchasePlanModalOpen(true);
            handleClose();
          }}
          disableRipple
        >
          <ShoppingCartIcon />
          Purchase Plan
        </MenuItem>
      </StyledMenu>
      {isPurchasePlanModalOpen && (
        <PurchasePlanModal
          onPlanPurchased={() => {
            handleReload && handleReload();
          }}
          customer={{
            ...customer,
            planEndDate: currentPlan ? currentPlan.endDate : null,
          }}
          isOpen={isPurchasePlanModalOpen}
          setIsOpen={setIsPurchasePlanModalOpen}
        />
      )}
      <ConfirmModal
        isOpen={openConfirmModal.isOpen}
        bodyText={openConfirmModal.text}
        onAccept={openConfirmModal.onAcceptCallback}
        acceptText={COMMON_TEXT.YES}
        onReject={openConfirmModal.onRejectCallback}
        rejectText={COMMON_TEXT.NO}
      />
    </div>
  );
};
