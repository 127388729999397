import React from 'react';
import { Formik } from 'formik';
import { usePickExpApi } from '@pickexp/core';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import {
  SectionHeader,
  ConfirmModal,
  DEFAULT_CONFIRM_MODAL_STATE,
  COMMON_TEXT,
} from '../common';
import { useNavigate } from 'react-router-dom';

import { ROUTE } from '../../constants/routes';

const InstructorForm = ({ instructor, onSave }) => {
  let navigate = useNavigate();
  const api = usePickExpApi().productApi;
  const { firstName, lastName, email, uuid } = instructor || {};
  const initialValues = {
    firstName: firstName || '',
    lastName: lastName || '',
    email: email || '',
  };

  if (uuid) {
    initialValues.uuid = uuid;
  }

  const navigateToInstructors = async () => {
    navigate(ROUTE.INSTRUCTORS);
  };

  const validateForm = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    if (!values.firstName) {
      errors.firstName = 'First name is required';
    }
    return errors;
  };

  const [openConfirmModal, setOpenConfirmModal] = React.useState(
    DEFAULT_CONFIRM_MODAL_STATE
  );

  const onFormSubmitHandler = async (values, { setSubmitting }) => {
    setOpenConfirmModal({
      isOpen: true,
      onAcceptCallback: async () => {
        const { data } = await api.createOrUpdateInstructor(values);
        onSave && onSave(data);
        setSubmitting(false);
        navigateToInstructors();
        return data.status === 'Success';
      },
      onRejectCallback: () => setOpenConfirmModal({ isOpen: false }),
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={onFormSubmitHandler}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <SectionHeader title={'New Instructor'} />

          <div className="instructorFormInputs">
            <input
              type="text"
              name="firstName"
              placeholder={'First Name'}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.firstName}
            />
            <input
              type="text"
              name="lastName"
              placeholder={'Last Name'}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.lastName}
            />
            <input
              type="email"
              name="email"
              placeholder={'Email'}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
            <div className={'feedbackContainer'}>
              <div> {errors.email && touched.email && errors.email}</div>
              <div>
                {errors.firstName && touched.firstName && errors.firstName}
              </div>
            </div>
            <div className={'actionContainer'}>
              <div className={'actionButtons'}>
                <Button
                  variant="contained"
                  startIcon={<ArrowBackIcon />}
                  onClick={navigateToInstructors}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={<SaveIcon />}
                  disabled={
                    isSubmitting ||
                    values.firstName === '' ||
                    values.email === ''
                  }
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
          <ConfirmModal
            isOpen={openConfirmModal.isOpen}
            bodyText={`Create instructor ${values.firstName}?`}
            onAccept={openConfirmModal.onAcceptCallback}
            acceptText={COMMON_TEXT.YES}
            onReject={openConfirmModal.onRejectCallback}
            rejectText={COMMON_TEXT.NO}
          />
        </form>
      )}
    </Formik>
  );
};

export default InstructorForm;
