import React from 'react';
import { Formik } from 'formik';
import Button from '@mui/material/Button';
import { usePickExpApi } from '@pickexp/core';
import './style.scss';
import { useOrgConfig } from '../../../hooks';

const BusinessBasicInfoForm = ({ onSave, onCancel }) => {
  const api = usePickExpApi().productApi;
  const orgConfig = useOrgConfig();

  const data = {
    entityUUID: orgConfig.entityUUID,
    name: orgConfig.name || '',
    reservationURL: orgConfig.reservationURL || '',
    websiteURL: orgConfig.website || '',
    status: orgConfig.status || '',
    logoURL: orgConfig.logoURL || '',
    allowedUsers: {},
  };

  const initialValues = {
    name: data.name,
    websiteURL: data.websiteURL,
    reservationURL: data.reservationURL,
    logoURL: data.logoURL,
  };

  const validateForm = (values) => {
    const errors = {};
    return errors;
  };

  const onFormSubmitHandler = async (values, { setSubmitting }) => {
    const { data } = await api.createOrUpdateEntity(values);
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={onFormSubmitHandler}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          {values.logoURL && (
            <div className="main_business_logo">
              <img src={values.logoURL} alt={'main_business_logo'} />
            </div>
          )}
          <input
            type="text"
            name="name"
            placeholder="Name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
          />
          <input
            type="text"
            name="websiteURL"
            placeholder="Website URL"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.websiteURL}
          />
          <input
            type="text"
            name="reservationURL"
            placeholder="Reservation URL"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.reservationURL}
          />

          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitting || !values.name}
            type="submit"
          >
            Save Info
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default BusinessBasicInfoForm;
