import React, { createRef } from 'react';
import DashboardView from './views/DashboardView';
import { TAB } from './components/nav';
import ClassReservationView from './views/ClassReservationView';
import ClassPublicCancelationView from './views/ClassPublicCancelationView';
import TKAttendanceView from './views/tk-attendance-view';
import ModalComponentReact from './components/PeReactModal/ModalComponentReact';
import { Routes, Route } from 'react-router-dom';
import { ROUTE } from './constants/routes';
import ViewCustomer from './components/Customers/view-customer';

const AppRoutes = (props) => {
  const { t } = props;
  const modal_ref = createRef();
  return (
    <>
      <ModalComponentReact ref={modal_ref} t={t}></ModalComponentReact>
      <Routes>
        <Route path={`${ROUTE.ORG}`}>
          <Route
            path={`${ROUTE.ORG}${ROUTE.ORG_SESSION_CANCELATION}`}
            element={<ClassPublicCancelationView />}
          />

          <Route
            path={`${ROUTE.ORG}${ROUTE.ORG_RESERVATION}`}
            element={<ClassReservationView />}
          />

          <Route
            path={`${ROUTE.ORG}${ROUTE.VIEW_SESSION_ATTENDANTS}`}
            element={<TKAttendanceView />}
          />
        </Route>

        <Route
          exact
          path={`${ROUTE.SESSIONS}`}
          element={<DashboardView activeTabName={TAB.SESSIONS.name} />}
        />

        <Route
          exact
          path={`${ROUTE.NEW_SESSION}`}
          element={
            <DashboardView
              activeTabName={TAB.SESSIONS.name}
              activeSubTabName={TAB.SESSIONS.subTab.SESSION_CREATE.name}
            />
          }
        />

        <Route
          exact
          path={`${ROUTE.EDIT_SESSION}`}
          element={
            <DashboardView
              activeTabName={TAB.SESSIONS.name}
              activeSubTabName={TAB.SESSIONS.subTab.SESSION_EDIT.name}
            />
          }
        />

        <Route
          exact
          path={`${ROUTE.CUSTOMERS}`}
          element={<DashboardView activeTabName={TAB.CUSTOMERS.name} />}
        />
        <Route
          exact
          path={`${ROUTE.EDIT_CUSTOMER}`}
          element={<DashboardView activeTabName={TAB.CUSTOMERS.name} />}
        />
        <Route
          exact
          path={`${ROUTE.VIEW_CUSTOMER}`}
          element={<ViewCustomer activeTabName={TAB.CUSTOMERS.name} />}
        />
        <Route
          exact
          path={`${ROUTE.NEW_CUSTOMER}`}
          element={<DashboardView activeTabName={TAB.CUSTOMERS.name} />}
        />

        <Route
          exact
          path={`${ROUTE.INSTRUCTORS}`}
          element={<DashboardView activeTabName={TAB.INSTRUCTORS.name} />}
        />
        <Route
          exact
          path={`${ROUTE.EDIT_INSTRUCTOR}`}
          element={<DashboardView activeTabName={TAB.INSTRUCTORS.name} />}
        />
        <Route
          exact
          path={`${ROUTE.NEW_INSTRUCTOR}`}
          element={<DashboardView activeTabName={TAB.INSTRUCTORS.name} />}
        />
        <Route
          exact
          path={`${ROUTE.SETTINGS}`}
          element={<DashboardView activeTabName={TAB.SETTINGS.name} />}
        />
        <Route
          exact
          path={`${ROUTE.HOME}`}
          element={<DashboardView activeTabName={TAB.DASHBOARD.name} />}
        />
      </Routes>
    </>
  );
};

export default AppRoutes;
