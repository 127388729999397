import { useContext } from 'react';
import { createContext } from 'react';

export const DEFAULT_APP_STATE = {
  sessionsCurrentWeekDay: '',
};

export const AppContext = createContext({});

export const useAppState = (props) => {
  return useContext(AppContext);
};

export const APP_STATE_VALUES = {
  CURRENT_WEEK_DAY_TAB_INDEX: 'CURRENT_WEEK_DAY_TAB_INDEX',
  CURRENT_SESSIONS_TAB: null,
};
