import { useContext, createContext } from 'react';
import { CONFIG } from '../constants/config';
import { usePickExpApi } from '@pickexp/core';
import { useAppState } from './useAppState';

export const OrgConfigContext = createContext({});

export const useOrgConfig = (props) => {
  return useContext(OrgConfigContext);
};

export const useOrgConfigValue = (k) => {
  const r = useOrgConfig();
  return r && r[k] && r[k].value;
};

export const useUpdateConfig = () => {
  const api = usePickExpApi().productApi;
  const { showErrorAlert, showSuccessAlert } = useAppState();
  return async ({ type, value, name }) => {
    const r = await api.updateOrgConfigs({
      [CONFIG[name]]: {
        type: type || 'json',
        value: value,
      },
    });
    if (r) {
      showSuccessAlert({
        message: 'Saved',
        autoHideDuration: 2000,
        onClose: (v) => {
          window.location.reload();
        },
      });
    } else {
      showErrorAlert({
        message: 'Error',
      });
    }
  };
};
