import React from 'react';
import Button from '@mui/material/Button';
import './style.scss';

export const TOGGLE_VIEW = {
  SESSIONS: 'SESSIONS',
  RESERVED_SESSIONS: 'RESERVED_SESSIONS',
  PLANS: 'PLANS',
};

const ReservationToggles = (props) => {
  const {
    getCustomerBookableSessions,
    dateRange,
    customerEmail,
    getCustomerReservedSessions,
    getPublicCustomerInfo,
    toggleView,
    setToggleView,
  } = props;

  return (
    <>
      <div className="publicSessionsToggle">
        <Button
          className={`leftButton ${
            toggleView === TOGGLE_VIEW.SESSIONS ? 'isSelected' : ''
          }`}
          onClick={() => {
            toggleView !== TOGGLE_VIEW.SESSIONS &&
              setToggleView(TOGGLE_VIEW.SESSIONS);
            getCustomerBookableSessions({
              email: customerEmail,
              dateRange,
            });
          }}
        >
          Clases
        </Button>
        <Button
          className={`middleButton ${
            toggleView === TOGGLE_VIEW.PLANS ? 'isSelected' : ''
          }`}
          onClick={() => {
            if (toggleView !== TOGGLE_VIEW.PLANS) {
              setToggleView(TOGGLE_VIEW.PLANS);
              getPublicCustomerInfo({ email: customerEmail });
            }
          }}
        >
          Mi Plan
        </Button>
        <Button
          className={`rightButton ${
            toggleView === TOGGLE_VIEW.RESERVED_SESSIONS ? 'isSelected' : ''
          }`}
          onClick={() => {
            if (toggleView !== TOGGLE_VIEW.RESERVED_SESSIONS) {
              setToggleView(TOGGLE_VIEW.RESERVED_SESSIONS);
              getCustomerReservedSessions();
            }
          }}
        >
          Mis Reservaciones
        </Button>
      </div>
    </>
  );
};

export default ReservationToggles;
