import React from 'react';
import ReactDOM from 'react-dom/client';
import './style.scss';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { PickexpApi, PickExpApiContext } from '@pickexp/core';
import { THEME, THEMES } from './constants/themes';
import { ThemeContext, OrgConfigContext } from './hooks';
import { getAPIConfig } from './config';
import { DEFAULT_ACCESS_VALUES } from './constants/config';

const root = ReactDOM.createRoot(document.getElementById('root'));
const apis = PickexpApi.getBaseApis({ API_CONFIG: getAPIConfig() });

const initiateDefaultConfig = async () => {
  const initiateDefaultConfigResult =
    await apis.productApi.initiateDefaultConfig();
};

const checkRequireConfigs = ({ MIN_RESERVATION_MINUTES }) => {
  if (!MIN_RESERVATION_MINUTES) {
    initiateDefaultConfig();
  }
};

const init = async () => {
  let orgConfigs = {
    ...DEFAULT_ACCESS_VALUES,
  };

  try {
    const getEntityResult = await apis.productApi.getEntity({
      withInfo: true,
      withConfigs: true,
      withUserInfo: true,
    });

    if (getEntityResult.data && getEntityResult.data.configs) {
      const orgConfigsData = getEntityResult.data.configs;
      if (orgConfigsData) {
        for (let i = 0; i < orgConfigsData.length; i++) {
          const c = orgConfigsData[i];
          orgConfigs[c.name] = c;
        }
      }
    }
    if (getEntityResult.data && getEntityResult.data.info) {
      const orgConfigsInfo = getEntityResult.data.info;
      if (orgConfigsInfo) {
        for (let k in orgConfigsInfo) {
          orgConfigs[k] = orgConfigsInfo[k];
        }

        if (orgConfigsInfo.plan && orgConfigsInfo.plan.features) {
          for (let k in orgConfigsInfo.plan.features) {
            orgConfigs[k] = orgConfigsInfo.plan.features[k];
          }
        }
      }
    }
    checkRequireConfigs(orgConfigs);
  } catch (err) {}

  root.render(
    <PickExpApiContext.Provider value={apis}>
      <OrgConfigContext.Provider value={orgConfigs}>
        <ThemeContext.Provider value={THEMES[THEME.LIGHT]}>
          <Router>
            <App />
          </Router>
        </ThemeContext.Provider>
      </OrgConfigContext.Provider>
    </PickExpApiContext.Provider>
  );
};

init();
