import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { usePickExpApi } from '@pickexp/core';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const PlanForm = ({ data, onSave, onCancel }) => {
  const [sessionTypesList, setSessionTypesList] = useState([]);
  const [value, setValue] = useState([]);
  const durationList = [
    { name: '1 Day Pass', value: 24 * 60 },
    { name: '7 Days Pass', value: 7 * 24 * 60 },
    { name: '1 Month', value: 30 * 24 * 60 },
    { name: '3 Months', value: 90 * 24 * 60 },
    { name: '6 Months', value: 180 * 24 * 60 },
    { name: '1 Year', value: 365 * 24 * 60 },
  ];

  const durationValue = durationList.find((r) => r.value === data.duration);

  const initialValues = {
    name: data.name,
    sessions: data.sessions,
    description: data.description,
    duration: durationValue?.value,
    amount: data.value,
    sessionTypes: [],
  };

  if (data.id) {
    initialValues.uuid = data.id;
  }
  const api = usePickExpApi().productApi;

  const validateForm = (values) => {
    const errors = {};
    return errors;
  };

  const createOrUpdatePlan = async (values) => {
    const { data } = await api.createOrUpdatePlan(values);
    onSave && onSave();
    return data.status === 'Success';
  };

  const onFormSubmitHandler = async (values, { setSubmitting }) => {
    await createOrUpdatePlan(values);
    setSubmitting(false);
  };

  useEffect(() => {
    const loadSessionTypeValue = (sessionTypesListData) => {
      const currentSessionTypeUUIDs = data.sessionType
        ? data.sessionType.map((r) => r.id)
        : [];
      const sessionTypeValue = sessionTypesListData.filter((r) =>
        currentSessionTypeUUIDs.includes(r.uuid)
      );
      setValue(sessionTypeValue);
    };
    const getSessionTypes = async () => {
      const { data } = await api.getSessionTypes();
      setSessionTypesList(data);
      loadSessionTypeValue(data);
    };
    getSessionTypes();
  }, [api, data.sessionType]);

  return (
    <Formik
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={onFormSubmitHandler}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          <input
            type="name"
            name="name"
            placeholder="Plan Name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
          />

          <input
            type="description"
            name="description"
            placeholder="Description Name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.description}
          />

          <input
            type="number"
            name="amount"
            placeholder="Plan Price"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.amount}
          />

          <FormControl fullWidth>
            <InputLabel id="duration-label">Duration</InputLabel>
            <Select
              style={{ textAlign: 'left' }}
              labelId="duration-label"
              id="duration-select"
              value={values.duration}
              onChange={(newValue) => {
                setFieldValue('duration', newValue.target.value);
              }}
            >
              {durationList.map((option) => (
                <MenuItem value={option.value}>{option.name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <input
            type="number"
            name="sessions"
            placeholder=" # Of Session"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.sessions}
          />
          <div>
            <Autocomplete
              multiple
              id="fixed-tags-demo"
              value={value}
              onChange={(event, newValue) => {
                setValue([...newValue]);
                setFieldValue('sessionTypes', newValue);
              }}
              options={sessionTypesList.filter(
                (option) => !value.some((v) => v.name === option.name)
              )}
              getOptionLabel={(option) => option.name}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip label={option.name} {...getTagProps({ index })} />
                ))
              }
              style={{ width: '100%' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={'Select Session Types'}
                  placeholder="Select Session Types"
                />
              )}
            />
          </div>

          <Button
            variant="contained"
            color="grey"
            type="button"
            onClick={() => {
              onCancel && onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={
              isSubmitting ||
              !values.name ||
              !values.sessions ||
              !values.duration ||
              !values.amount ||
              !values.sessionTypes
            }
            type="submit"
          >
            Save
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default PlanForm;
