import React, { useState, useCallback, useEffect } from 'react';
import { Search, ListContainer, DynamicList } from '../common';

import CustomerForm from './customer-form';
import { ROUTE, ROUTE_PARAM } from '../../constants/routes';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { usePickExpApi } from '@pickexp/core';
import CustomerListRow from './customer-list-row';
import { useOrgConfig } from '../../hooks';
import {
  DEFAULT_CONFIRM_MODAL_STATE,
  ConfirmModal,
  COMMON_TEXT,
} from '../common';
import { PurchasePlanModal } from './purchase-plan-modal';
import './style.scss';

const Customers = () => {
  const params = useParams();
  const orgConfig = useOrgConfig();
  let location = useLocation();
  let navigate = useNavigate();
  const [customer, setCustomer] = useState();
  const [list, setList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const isListView = ROUTE.CUSTOMERS === location.pathname;
  const [isLoading, setIsLoading] = useState(true);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [hasCustomerLoaded, setHasCustomerLoaded] = useState(false);
  const [isPurchasePlanModalOpen, setIsPurchasePlanModalOpen] = useState(false);
  const MAX_LIST_RENDERED_RECORS = 20;
  const [openConfirmModal, setOpenConfirmModal] = useState(
    DEFAULT_CONFIRM_MODAL_STATE
  );

  const api = usePickExpApi().productApi;

  const getCustomers = useCallback(
    async ({ q }) => {
      setIsLoading(true);
      const { data } = await api.getCustomers({ q });
      setHasLoaded(true);
      setList(data);
      setSearchQuery(q);
      setIsLoading(false);
    },
    [api]
  );

  const onRowClickHandler = ({ row }) => {
    setCustomer(row);
  };

  const onAddNewCustomerClickHandler = ({ row }) => {
    navigate(ROUTE.NEW_CUSTOMER);
  };

  const onClickPurchasePlan = ({ uuid, firstName }) => {
    setCustomer({
      uuid,
      firstName,
    });
    setIsPurchasePlanModalOpen(true);
  };

  const onDelete = async ({ uuid, firstName }) => {
    setOpenConfirmModal({
      text: `Seguro que desea eliminar el cliente ${firstName}?`,
      isOpen: true,
      onAcceptCallback: async () => {
        await api.deleteCustomer({ uuid });
        getCustomers({ q: searchQuery || '' });
        setOpenConfirmModal({ isOpen: false });
      },
      onRejectCallback: () => {
        setOpenConfirmModal({ isOpen: false });
      },
    });
  };

  const getCustomer = useCallback(async () => {
    const { data } = await api.getCustomer({
      uuid: params[ROUTE_PARAM.CUSTOMER_ID],
      withEmail: true,
    });
    setHasCustomerLoaded(true);
    setCustomer(data);
  }, [api, params]);

  useEffect(() => {
    !hasLoaded && getCustomers({ q: searchQuery || '' });
  }, [hasLoaded, searchQuery, getCustomers]);

  useEffect(() => {
    !hasCustomerLoaded && !isListView && getCustomer();
  }, [hasCustomerLoaded, isListView, getCustomer]);

  return (
    <div className={'customers'}>
      {isListView &&
        (orgConfig.canAddCustomers ? (
          <Search
            placeholder={'Search customer by name'}
            buttonText={'Add New Customer'}
            onClick={onAddNewCustomerClickHandler}
            onType={(value) => {
              getCustomers({ q: value });
            }}
          />
        ) : (
          <Search
            placeholder={'Search customer by name'}
            onType={(value) => {
              getCustomers({ q: value });
            }}
          />
        ))}
      <ListContainer>
        {isListView && (
          <DynamicList
            noHeader
            onRowClick={onRowClickHandler}
            data={list.slice(0, MAX_LIST_RENDERED_RECORS)}
            isLoading={isLoading}
            columns={[
              {
                key: 'firstName',
                header: 'Name',
                render: ({ rowData, index }) => {
                  return (
                    <CustomerListRow
                      key={`customerListRow-${index}`}
                      {...rowData}
                      onClickPurchasePlan={onClickPurchasePlan}
                      onDelete={onDelete}
                      isSelected={customer && customer.uuid === rowData.uuid}
                    />
                  );
                },
              },
            ]}
          />
        )}
      </ListContainer>
      {!isListView && hasLoaded && hasCustomerLoaded && (
        <CustomerForm
          customer={params[ROUTE_PARAM.CUSTOMER_ID] ? customer : {}}
          onSave={getCustomers}
        />
      )}

      {isPurchasePlanModalOpen && (
        <PurchasePlanModal
          onPlanPurchased={() => {
            getCustomers({ q: searchQuery || '' });
          }}
          customer={{
            ...customer,
            planEndDate:
              customer &&
              customer.plans &&
              customer.plans[0] &&
              customer.plans[0].planExpDate,
          }}
          isOpen={isPurchasePlanModalOpen}
          setIsOpen={setIsPurchasePlanModalOpen}
        />
      )}

      <ConfirmModal
        isOpen={openConfirmModal.isOpen}
        bodyText={openConfirmModal.text}
        onAccept={openConfirmModal.onAcceptCallback}
        acceptText={COMMON_TEXT.YES}
        onReject={openConfirmModal.onRejectCallback}
        rejectText={COMMON_TEXT.NO}
      />
    </div>
  );
};

export default Customers;
