import React from 'react';
import { InputBase, IconButton, Button, Fab } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import './search.scss';

function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export const Search = ({ placeholder, buttonText, onClick, onType }) => {
  const a = debounce(function (v) {
    onType(v);
  });

  return (
    <div className={`search-section`}>
      <div>
        <InputBase
          className={'useStyleInput'}
          placeholder={placeholder || 'Search'}
          inputProps={{ 'aria-label': 'search google maps' }}
          onChange={(e) => {
            onType && a(e.target.value);
          }}
        />
        <IconButton
          type="button"
          aria-label="search"
          style={{ marginRight: '10px' }}
        >
          <SearchIcon />
        </IconButton>
      </div>

      {onClick && (
        <Button variant="contained" onClick={onClick}>
          {buttonText}
        </Button>
      )}
    </div>
  );
};
export const AddButton = ({ onClick }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Fab color="primary" aria-label="add">
        <AddIcon onClick={onClick} />
      </Fab>
    </div>
  );
};
