const baseServiceUrl = '/api/baby/v1';

export default ({ peFetch }) => ({
  getFamilyBabies: async ({ familyID }) => {
    return peFetch.post(`${baseServiceUrl}/public/family/${familyID}/babies`);
  },
  saveBabyNewEntry: async ({ familyID, babyID, entry }) => {
    return peFetch.post(
      `${baseServiceUrl}/public/family/${familyID}/baby/${babyID}/entry`,
      { entry }
    );
  },
  getTodayBabyLog: async ({ familyID, babyID, startDate, endDate }) => {
    return peFetch.post(
      `${baseServiceUrl}/public/family/${familyID}/baby/${babyID}/logs`,
      { startDate, endDate }
    );
  },
});
