import React from 'react';
import { UTCToLocalFormat } from '../../utils/date-utils';
import { SimpleCell } from '../common';
import Button from '@mui/material/Button';

export const UpcomingActivitiesRow = ({
  activity,
  index,
  onlyLabels,
  onClickCancel,
}) => {
  return (
    <div className="upcomingActivitiesRow">
      {!onlyLabels && (
        <SimpleCell
          label={'Session Date'}
          value={UTCToLocalFormat(activity.startDate, 'LLLL')}
        />
      )}

      {!onlyLabels && (
        <SimpleCell
          label={'Session Name'}
          value={activity.sessionName || activity.sessionTypeName}
        />
      )}

      {!onlyLabels && (
        <SimpleCell label={'Instructor'} value={activity.instructorName} />
      )}

      {!onlyLabels && (
        <SimpleCell
          label={'Controls'}
          value={
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                onClickCancel && onClickCancel();
              }}
            >
              Cancel
            </Button>
          }
        />
      )}
    </div>
  );
};
