import React from 'react';
import { UTCToLocalDateDiff, OPTION } from '../../utils/date-utils';

export const DurationLabel = ({ startDate, endDate }) => {
  let differenceTimeInMinutes = UTCToLocalDateDiff(
    endDate,
    startDate,
    OPTION.MINUTES
  );
  if (differenceTimeInMinutes >= 60) {
    const differenceTimeInHours = UTCToLocalDateDiff(
      endDate,
      startDate,
      OPTION.HOURS
    );

    const remainingMinutes =
      differenceTimeInMinutes - 60 * differenceTimeInHours;
    return (
      <div>
        {`${differenceTimeInHours} ${
          differenceTimeInHours > 1 ? 'Hours' : 'Hour'
        }`}
        {remainingMinutes ? ` and ${remainingMinutes} Minutes` : null}
      </div>
    );
  } else {
    return <div>{`${differenceTimeInMinutes} Minutes`}</div>;
  }
};

export const isDurationTooLong = ({ startDate, endDate, maxHours }) => {
  let differenceTime = UTCToLocalDateDiff(endDate, startDate, OPTION.MINUTES);
  if (differenceTime > 60) {
    differenceTime = UTCToLocalDateDiff(endDate, startDate, OPTION.HOURS);
    return differenceTime > maxHours;
  } else {
    return false;
  }
};
