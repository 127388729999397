import React, { useState, useCallback, useEffect } from 'react';
import { CustomModal, DynamicList } from '../common';
import { usePickExpApi } from '@pickexp/core';
import {
  getWithMinutesRange,
  getUTCWithMinutesRange,
  UTCToLocalMoment,
} from '../../utils/date-utils';
import Button from '@mui/material/Button';
import './purchase-plan-modal.scss';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import moment from 'moment';

const PLAN_PURCHASE_TYPE = {
  STARTS_NOW: 'STARTS_NOW',
  START_END_OF_CURRENT: 'START_END_OF_CURRENT',
  STARTS_ON_DATE: 'STARTS_ON_DATE',
};

export const PurchasePlanModal = ({
  onPlanPurchased,
  customer,
  isOpen,
  onClose,
  setIsOpen,
}) => {
  const [selectedPlan, setSelectedPlan] = useState();
  const [planList, setPlanList] = useState([]);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [customStartDate, setCustomStartDate] = useState(moment());

  const [planPurchaseType, setPlanPurchaseType] = useState(
    PLAN_PURCHASE_TYPE.STARTS_NOW
  );

  const api = usePickExpApi().productApi;
  const closePurchasePlanModal = () => {
    setIsOpen(false);
    setSelectedPlan(null);
    onClose && onClose();
  };

  const getPlans = useCallback(async () => {
    const { data } = await api.getPlans();
    setPlanList(data);
    setHasLoaded(true);
    setIsOpen(true);
  }, [api, setIsOpen]);

  const { planEndDate } = customer;

  const getPlanStartDate = () => {
    let nDate;
    if (planPurchaseType === PLAN_PURCHASE_TYPE.STARTS_NOW) {
      nDate = moment().toDate();
    } else if (planPurchaseType === PLAN_PURCHASE_TYPE.START_END_OF_CURRENT) {
      nDate = UTCToLocalMoment(planEndDate).toDate();
    } else if (planPurchaseType === PLAN_PURCHASE_TYPE.STARTS_ON_DATE) {
      nDate = customStartDate;
    }
    return nDate;
  };

  const onClickAddPurchasedPlanHandler = async () => {
    closePurchasePlanModal();
    const { startDate, endDate } = getUTCWithMinutesRange({
      startDate: getPlanStartDate(),
      minutes: selectedPlan.duration,
    });
    await api.purchasePlan({
      startDate,
      endDate,
      customerUUID: customer.uuid,
      planId: selectedPlan.id,
    });
    onPlanPurchased && onPlanPurchased();
  };

  useEffect(() => {
    !hasLoaded && isOpen && getPlans();
  }, [hasLoaded, isOpen, getPlans]);

  const newStartDate = selectedPlan
    ? getWithMinutesRange({
        startDate: getPlanStartDate(),
        minutes: selectedPlan.duration,
        format: 'LLLL',
      })
    : {};

  const toCOLCurrency = (value) => {
    let newV = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: 'COP',
    }).format(value);
    newV = newV.replace('.00', '').replace('COP', '$');
    return newV;
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={closePurchasePlanModal}
      className={'purchase-plan-modal'}
    >
      <h3> Purchased plan</h3>
      {selectedPlan && (
        <div style={{ textAlign: 'center' }}>
          {selectedPlan.name} - {toCOLCurrency(selectedPlan.value)}
        </div>
      )}

      {!selectedPlan && (
        <div style={{ height: '200px' }}>
          <DynamicList
            noHeader
            onRowClick={({ row }) => {
              setSelectedPlan(row);
            }}
            data={planList.filter((p) => p.isActive)}
            isLoading={false}
            enableSelectedRow
            columns={[
              {
                key: 'name',
                header: '',
                render: ({ rowData }) => {
                  return (
                    <div
                      style={{
                        minHeight: '40px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '10px',
                        cursor: 'pointer',
                      }}
                    >
                      {rowData.name} - {toCOLCurrency(rowData.value)}
                    </div>
                  );
                },
              },
            ]}
          />
        </div>
      )}

      {selectedPlan && (
        <div className="planPurchaseTypeContainer">
          <div
            className={`planPurchaseTypeOption ${
              planPurchaseType === PLAN_PURCHASE_TYPE.STARTS_NOW
                ? 'selected'
                : ''
            }`}
            onClick={() => {
              setPlanPurchaseType(PLAN_PURCHASE_TYPE.STARTS_NOW);
            }}
          >
            Starts now
          </div>

          {planEndDate && (
            <div
              className={`planPurchaseTypeOption ${
                planPurchaseType === PLAN_PURCHASE_TYPE.START_END_OF_CURRENT
                  ? 'selected'
                  : ''
              }`}
              onClick={() => {
                setPlanPurchaseType(PLAN_PURCHASE_TYPE.START_END_OF_CURRENT);
              }}
            >
              Starts when current plan ends
            </div>
          )}

          <div
            className={`planPurchaseTypeOption ${
              planPurchaseType === PLAN_PURCHASE_TYPE.STARTS_ON_DATE
                ? 'selected'
                : ''
            }`}
            onClick={() => {
              setPlanPurchaseType(PLAN_PURCHASE_TYPE.STARTS_ON_DATE);
            }}
          >
            On customer date
          </div>
        </div>
      )}

      {planPurchaseType === PLAN_PURCHASE_TYPE.STARTS_ON_DATE && (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            value={customStartDate}
            onChange={(newValue) => {
              setCustomStartDate(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      )}

      {newStartDate.startDate && (
        <div className="startDateContainer">{newStartDate.startDate}</div>
      )}

      <div style={{ textAlign: 'center', marginTop: '10px' }}>
        <Button variant="contained" onClick={closePurchasePlanModal}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!selectedPlan}
          onClick={onClickAddPurchasedPlanHandler}
        >
          Purchase Plan
        </Button>
      </div>
    </CustomModal>
  );
};
