import React, { useState } from 'react';
import { Formik } from 'formik';
import { usePickExpApi } from '@pickexp/core';
import Button from '@mui/material/Button';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import BlockIcon from '@mui/icons-material/Block';
import TextField from '@mui/material/TextField';
import {
  ConfirmModal,
  DEFAULT_CONFIRM_MODAL_STATE,
  COMMON_TEXT,
} from '../../common';
import {
  LocalToUTCFormat,
  LocalToUTCEndOfDayFormat,
} from '../../../utils/date-utils';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import './style.scss';

export const CustomerPlanAdvanceOptionForm = ({
  plan,
  onSave,
  isAdd = true,
  onClose,
  customerUUID,
}) => {
  const api = usePickExpApi().productApi;

  const [openConfirmModal, setOpenConfirmModal] = useState(
    DEFAULT_CONFIRM_MODAL_STATE
  );

  const adjustCustomerPlan = async (values) => {
    const { data } = await api.adjustCustomerPlan({
      customerUUID,
      planUUID: plan.planUUID,
      planExpDate: LocalToUTCFormat(values.plan.planExpDate),
      newPlanExpDate: LocalToUTCEndOfDayFormat(values.plan.newPlanExpDate),
      adjustAmount: values.plan.adjustAmount,
      isAdd,
      activityId: plan.sessionID,
    });

    onSave && onSave(data);
    return data.status === 'Success';
  };

  const onFormSubmitHandler = async (values, { setSubmitting }) => {
    setOpenConfirmModal({
      isOpen: true,
      onAcceptCallback: async () => {
        adjustCustomerPlan(values);
        setSubmitting(false);
        setOpenConfirmModal({ isOpen: false });
      },
      onRejectCallback: () => setOpenConfirmModal({ isOpen: false }),
    });
  };

  const initialValues = {
    plan,
  };

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={onFormSubmitHandler}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <div className="advanceOptionsContainer">
              {plan ? (
                <div>
                  <div className="customerPlanRow">
                    <div> {plan.planName} </div>
                    <input
                      type="number"
                      name={`plan.adjustAmount`}
                      placeholder={
                        isAdd ? 'Increase Number' : 'Decrease Number'
                      }
                      onChange={(event) => {
                        const value = event.target.value;
                        setFieldValue(`plan.adjustAmount`, Number(value));
                      }}
                      onBlur={handleBlur}
                      value={plan.newPlanTotalReservationLeft}
                    />
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label="New expiration date"
                        value={values.plan.newPlanExpDate || plan.planExpDate}
                        onChange={(newValue) => {
                          setFieldValue(
                            `plan.newPlanExpDate`,
                            newValue.toDate()
                          );
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </div>

                  {values.plan.adjustAmount > plan.weight && (
                    <div className="formErrorMessage">
                      <BlockIcon />

                      <div>
                        Plan adjustment amount can't be more then the plan total
                        sessions
                      </div>
                    </div>
                  )}

                  {values.plan.adjustAmount < 0 && (
                    <div className="formErrorMessage">
                      <BlockIcon />
                      <div>Plan adjustment amount can't be less than zero</div>
                    </div>
                  )}

                  {values.plan.newPlanExpDate && (
                    <div className="formWraningMessage">
                      <WarningAmberIcon />
                      <div>
                        Warning updating expiration date will change plan
                        expiration date.
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="noplans">No plans</div>
              )}
              <div className={'actionContainer'}>
                <div className={'actionButtons'}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      onClose && onClose();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={
                      isSubmitting ||
                      values.plan.adjustAmount < 0 ||
                      values.plan.adjustAmount > plan.weight
                    }
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
      <ConfirmModal
        isOpen={openConfirmModal.isOpen}
        bodyText={`Update plan?`}
        onAccept={openConfirmModal.onAcceptCallback}
        acceptText={COMMON_TEXT.YES}
        onReject={openConfirmModal.onRejectCallback}
        rejectText={COMMON_TEXT.NO}
      />
    </>
  );
};
