import React from 'react';
import { toCurrency } from '../../../utils/transform';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useOrgConfig } from '../../../hooks';
import { DEFAULT_APP_PLANS } from '../../../constants/config';
import './plans.scss';

const PlansContainer = () => {
  const orgConfig = useOrgConfig();

  const activePlan = orgConfig.plan;
  const plans = DEFAULT_APP_PLANS.map((plan) => {
    if (activePlan && activePlan.name) {
      plan.isActive = false;
      if (plan.name === activePlan.name) {
        plan.isActive = true;
      }
    }
    return plan;
  });

  return (
    <div className="plans_container">
      {plans &&
        plans
          .filter((plan) => plan.name !== 'jumbo')
          .map(({ name, amount, currency, isActive, features }, index) => {
            const FeatureIcon = ({ value }) => {
              return value ? (
                <CheckIcon className="isCheck" />
              ) : (
                <CloseIcon className="isClose" />
              );
            };

            return (
              <div
                className={`plan_card ${isActive ? 'selected' : ''}`}
                key={`plan-item-${index}`}
              >
                <h3>{name}</h3>
                <div className="plan_value">
                  {`${toCurrency({ amount, currency, symbol: true })}/mo`}
                </div>

                {isActive && (
                  <button>{isActive ? 'Selected' : 'Get Plan'}</button>
                )}

                <div className="spliter"></div>
                <div className="plan_features">
                  <h4>Top features </h4>
                  <div className="plan_feature_row">
                    <FeatureIcon value={features.customersLimit} /> Manage up to{' '}
                    <b>{features.customersLimit}</b> customers
                  </div>
                  <div className="plan_feature_row">
                    <FeatureIcon value={features.manageInstructors} /> Manage
                    instructors
                  </div>
                  <div className="plan_feature_row">
                    <FeatureIcon value={features.manageCustomerEmails} /> Manage
                    customer confirmation emails
                  </div>
                  <div className="plan_feature_row">
                    <FeatureIcon value={features.manageCustomerPlans} /> Manage
                    customer plans punch cards plans
                  </div>
                  <div className="plan_feature_row">
                    <FeatureIcon
                      value={features.customerReservationDataRetentionDays}
                    />
                    Customer reservation history up to{' '}
                    <b>{features.customerReservationDataRetentionDays}</b> days
                  </div>
                  <div className="plan_feature_row">
                    <FeatureIcon value={features.adminUsers} />{' '}
                    <b>{features.adminUsers}</b> Adminstrator accounts
                  </div>
                  <div className="plan_feature_row">
                    <FeatureIcon value={features.ticketSupport} /> Ticker
                    support
                  </div>
                  <div className="plan_feature_row">
                    <FeatureIcon value={features.chatSupport} /> Chat support
                  </div>
                </div>
              </div>
            );
          })}
    </div>
  );
};

export default PlansContainer;
