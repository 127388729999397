import React from 'react';
import { ActionSection } from '../../common';

const SessionTypeListRow = ({ name, isSelected, onEdit, onDelete }) => {
  return (
    <div className={'session-type-list-row'}>
      <div>{name}</div>
      <ActionSection
        canEdit
        onEdit={onEdit}
        canDelete
        onDelete={onDelete}
        isWhite
      />
    </div>
  );
};

export default SessionTypeListRow;
