import React from 'react';
import BusinessInfoForm from './basic-info-form';
import BusinessTemplates from './templates';
import { SessionWeekDays } from './session-week-days';
import { UserProfile } from './user-profile';
import { MinReservationTime } from './min-reservation-time';
import { AdvanceReservationWindow } from './advance-reservation-window';
import { SectionHeader } from '../../common';
import PlansContainer from './plans';

const Business = () => {
  return (
    <div className="business-view">
      <SectionHeader title={'Account'} />
      <UserProfile />

      <SectionHeader title={'Info'} subTitle={'Basic business information'} />
      <BusinessInfoForm />
      <SectionHeader
        title={'Weekly session days'}
        subTitle={'Select days your business is open'}
      />
      <SessionWeekDays />

      <SectionHeader
        title={'Minimum Reservation Time'}
        subTitle={
          'Select the minimum time a customer can reverse or cancel a session'
        }
      />
      <MinReservationTime />

      <SectionHeader
        title={'Advance reservation windows'}
        subTitle={'Select the day reservations can start with an end day'}
      />
      <AdvanceReservationWindow />
      <BusinessTemplates />
      <SectionHeader title={'Active Plan'} />
      <PlansContainer />

      <div className="platform-details">
        <div className="platform-version">
          {' '}
          {`v${process.env.REACT_APP_VERSION}` || 'v0.0.0'}
        </div>
        <div className="platform-logout">Log out</div>
      </div>
    </div>
  );
};

export default Business;
