import React, { useState } from 'react';
import { usePickExpApi } from '@pickexp/core';
import {
  DEFAULT_CONFIRM_MODAL_STATE,
  ConfirmModal,
  COMMON_TEXT,
} from './modal';

export const CancelReservation = ({
  customerUUID,
  sessionActivityUUID,
  isRefund,
  cb,
  isOpen,
}) => {
  const api = usePickExpApi().productApi;
  const [openConfirmModal, setOpenConfirmModal] = useState(
    DEFAULT_CONFIRM_MODAL_STATE
  );

  isOpen &&
    !openConfirmModal.isOpen &&
    setOpenConfirmModal({
      text: `Seguro que desea ${
        isRefund ? 'cancelar and refundar' : 'cancelar'
      } esta reservacion`,
      isOpen: true,
      onAcceptCallback: async () => {
        await api.cancelSession({
          customerUUID,
          sessionActivityUUID,
          isRefund,
          reason: 'CANCELED_BY_ORG_ON_BEHALF',
        });
        cb && cb({ accepted: true });
        setOpenConfirmModal({ isOpen: false });
      },
      onRejectCallback: () => {
        cb && cb({ accepted: false });
        setOpenConfirmModal({ isOpen: false });
      },
    });

  return (
    <>
      <ConfirmModal
        isOpen={openConfirmModal.isOpen}
        bodyText={openConfirmModal.text}
        onAccept={openConfirmModal.onAcceptCallback}
        acceptText={COMMON_TEXT.YES}
        onReject={openConfirmModal.onRejectCallback}
        rejectText={COMMON_TEXT.NO}
      />
    </>
  );
};
