import React, { useState } from 'react';
import { usePickExpApi } from '@pickexp/core';
import Paper from '@mui/material/Paper';
import { useParams } from 'react-router-dom';
import {
  getDateUTCRange,
  getCurrentUTCDate,
  getWeekDayName,
} from '../../utils/date-utils';
import './style.scss';
import {
  ConfirmModal,
  DEFAULT_CONFIRM_MODAL_STATE,
  COMMON_TEXT,
} from '../../components/common';

import ClientLogin from './reservation-login';
import ReservationToggles, { TOGGLE_VIEW } from './reservation-toggles';
import ReservationPlans from './reservation-plans';
import ReservationBooked from './reservation-booked';
import ReservationSessions from './reservation-sessions';

const ClassReservationView = () => {
  const CLIENT_ERROR_ATTEMPT_LIMIT = 3;
  const [isClientBlocked, setIsClientBlocked] = useState(false);
  const [clientAttemps, setClientAttemps] = useState(0);
  const { ORG_ID } = useParams();
  const businessUUID = ORG_ID;
  const [toggleView, setToggleView] = useState(TOGGLE_VIEW.SESSIONS);
  const [bookableSessions, setBookableSessions] = useState([]);
  const [reservedSessions, setReservedSessions] = useState();
  const [customerEmail, setCustomerEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingReservedSessions, setLoadingReservedSessions] = useState(false);
  const [loadingCustomerInfo, setLoadingCustomerInfo] = useState(false);
  const [isValidCustomer, setIsValidCustomer] = useState(false);

  const initDateRange = getDateUTCRange(new Date());
  initDateRange.startDate = getCurrentUTCDate();
  const [dateRange, setDateRange] = useState(getDateUTCRange(new Date()));
  const [customer, setCustomer] = useState();
  const [noFoundPlaceholder, setNoFoundPlaceholder] = useState('');
  const [openConfirmModal, setOpenConfirmModal] = React.useState(
    DEFAULT_CONFIRM_MODAL_STATE
  );

  const resetView = () => {
    setCustomerEmail('');
    setIsValidCustomer(false);
    setBookableSessions([]);
  };

  const [overridePublicOrgConfigValue, setOverridePublicOrgConfigValue] =
    useState();

  const api = usePickExpApi().productApi;

  const getPublicCustomerInfo = async ({ email }) => {
    setLoadingCustomerInfo(true);
    const { data } = await api.getPublicCustomerInfo({
      email,
      orgUUID: businessUUID,
    });
    setCustomer(data);
    setLoadingCustomerInfo(false);
  };

  const getCustomerBookableSessions = async ({ email, date }) => {
    setLoading(true);

    const { startDate, endDate } = date ? date : dateRange || {};
    try {
      const { data } = await api.getCustomerBookableSessions({
        email,
        orgUUID: businessUUID,
        startDate,
        endDate,
        weekDay: getWeekDayName(startDate),
      });

      if (!overridePublicOrgConfigValue) {
        const pcdata = await getPublicOrgConfigByCustomer({
          email,
        });
        pcdata && setOverridePublicOrgConfigValue(pcdata);
      }

      if (data) {
        setIsValidCustomer(true);
        setBookableSessions(data || []);
        getPublicCustomerInfo({ email: customerEmail });
        setNoFoundPlaceholder(false);
      } else {
        setIsValidCustomer(false);
      }
    } catch ({ dataMessage, error }) {
      if (error && error.response && error.response.status === 403) {
        if (!isClientBlocked) {
          setClientAttemps(clientAttemps + 1);
          if (clientAttemps + 1 >= CLIENT_ERROR_ATTEMPT_LIMIT) {
            setIsClientBlocked(true);
          }
        }
      }

      if (dataMessage === 'No active plan found') {
        setIsValidCustomer(true);
        setBookableSessions([]);
        setNoFoundPlaceholder('Su plan ha caducado');
        getPublicCustomerInfo({ email: customerEmail });
      }
    }

    setLoading(false);
  };

  const getCustomerReservedSessions = async () => {
    setLoadingReservedSessions(true);
    const { data } = await api.getCustomerReservedSessions({
      email: customerEmail,
      orgUUID: businessUUID,
    });
    data && setReservedSessions(data);
    setLoadingReservedSessions(false);
  };

  const getPublicOrgConfigByCustomer = async ({ email }) => {
    let orgConfigs = {};
    try {
      const { data: orgConfigsData } = await api.getPublicOrgConfigByCustomer({
        email,
        orgUUID: ORG_ID,
      });
      if (orgConfigsData) {
        for (let i = 0; i < orgConfigsData.length; i++) {
          const c = orgConfigsData[i];
          orgConfigs[c.name] = c;
        }
      }
      return orgConfigs;
    } catch (err) {
      return orgConfigs;
    }
  };

  const handleFindSession = ({ date }) => {
    if (!isClientBlocked) {
      setDateRange(date);
      getCustomerBookableSessions({ email: customerEmail, date });
    }
  };

  return (
    <div className={'public-reservation-view'}>
      <Paper
        variant="outlined"
        className={`formContainer ${isValidCustomer ? 'expanded' : ''}`}
      >
        {!isValidCustomer && (
          <ClientLogin
            handleFindSession={handleFindSession}
            customerEmail={customerEmail}
            setCustomerEmail={setCustomerEmail}
            orgUUID={businessUUID}
          />
        )}

        {isValidCustomer && (
          <>
            <ReservationToggles
              getCustomerBookableSessions={getCustomerBookableSessions}
              dateRange={dateRange}
              customerEmail={customerEmail}
              getCustomerReservedSessions={getCustomerReservedSessions}
              getPublicCustomerInfo={getCustomerReservedSessions}
              toggleView={toggleView}
              setToggleView={setToggleView}
            />

            {toggleView === TOGGLE_VIEW.PLANS && (
              <ReservationPlans
                loadingCustomerInfo={loadingCustomerInfo}
                plans={customer && customer.plans ? customer.plans : []}
              />
            )}

            {toggleView === TOGGLE_VIEW.SESSIONS && (
              <ReservationSessions
                resetView={resetView}
                handleFindSession={handleFindSession}
                loading={loading}
                noFoundPlaceholder={noFoundPlaceholder}
                setOpenConfirmModal={setOpenConfirmModal}
                overridePublicOrgConfigValue={overridePublicOrgConfigValue}
                bookableSessions={bookableSessions}
                customerEmail={customerEmail}
                businessUUID={businessUUID}
                dateRange={dateRange}
                setDateRange={setDateRange}
              />
            )}

            {toggleView === TOGGLE_VIEW.RESERVED_SESSIONS && (
              <ReservationBooked
                getCustomerReservedSessions={getCustomerReservedSessions}
                reservedSessions={reservedSessions}
                loadingReservedSessions={loadingReservedSessions}
              />
            )}
          </>
        )}
      </Paper>
      <ConfirmModal
        isOpen={openConfirmModal.isOpen}
        bodyText={`Seguro que desea reservas esta clase?`}
        onAccept={openConfirmModal.onAcceptCallback}
        acceptText={COMMON_TEXT.YES}
        onReject={openConfirmModal.onRejectCallback}
        rejectText={COMMON_TEXT.NO}
      />
    </div>
  );
};

export default ClassReservationView;
