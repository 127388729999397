import React, { useState } from 'react';
import './style.scss';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ListSessions } from '../Sessions';
import CreateSession from '../Sessions/create-session';
import EditSession from '../Sessions/edit-session';
import Customers from '../Customers';
import Instructor from '../instructors';
import Dashboard from '../dashboard';
import Settings from '../Settings';
import { useNavigate } from 'react-router-dom';
import { ROUTE, ROUTE_PARAM } from '../../constants/routes';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import { DotMenu } from '../common/dot-menu';
import { useOrgConfig } from '../../hooks';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import BadgeIcon from '@mui/icons-material/Badge';
import SettingsIcon from '@mui/icons-material/Settings';
import EventNoteIcon from '@mui/icons-material/EventNote';

export const TAB = {
  DASHBOARD: {
    route: ROUTE.HOME,
    name: 'DASHBOARD',
    label: 'Dashboard',
    Render: Dashboard,
    icon: DashboardIcon,
  },
  SESSIONS: {
    route: ROUTE.SESSIONS,
    name: 'SESSIONS',
    label: 'Sessions',
    Render: ListSessions,
    icon: EventNoteIcon,
    subTab: {
      SESSION_CREATE: {
        route: ROUTE.NEW_SESSION,
        name: 'SESSION_CREATE',
        Render: CreateSession,
      },
      SESSION_EDIT: {
        route: ROUTE.EDIT_SESSION,
        name: 'SESSION_EDIT',
        Render: EditSession,
      },
    },
  },

  CUSTOMERS: {
    route: ROUTE.CUSTOMERS,
    name: 'CUSTOMERS',
    label: 'Customers',
    Render: Customers,
    icon: PersonAddIcon,
  },

  INSTRUCTORS: {
    route: ROUTE.INSTRUCTORS,
    name: 'INSTRUCTORS',
    label: 'Instructors',
    Render: Instructor,
    icon: BadgeIcon,
  },

  SETTINGS: {
    route: ROUTE.SETTINGS.replace(
      ROUTE_PARAM.SETTING_NAME,
      ROUTE_PARAM.BUSINESS.toLowerCase()
    ),
    name: 'SETTINGS',
    label: 'Account',
    Render: Settings,
    icon: SettingsIcon,
  },
};

const accountMenuItems = [
  {
    onClick: () => {},
    text: 'Brayan',
  },
  {
    onClick: () => {},
    EditIcon: AccountCircleIcon,
    text: 'Profile',
  },
];

export const NavBar = ({ activeTabName }) => {
  const activeTabNameValue = activeTabName || TAB.SESSIONS.name;
  const [expandNav, setExpandNav] = useState(false);
  let navigate = useNavigate();
  const orgConfig = useOrgConfig();

  const tabList = Object.keys(TAB).filter((tab) => {
    if (!orgConfig.canUseInstructors && tab === 'INSTRUCTORS') {
      return false;
    }
    return tab;
  });
  const activeTabIndex = tabList.indexOf(activeTabNameValue);
  const handleChange = (event, newValue) => {
    const newActiveTab = TAB[tabList[newValue]];
    navigate(newActiveTab.route);
  };

  const handleChangeV2 = (newActiveTab) => {
    navigate(newActiveTab.route);
  };

  const showMenu = false;

  const isNavBarV2 = true;

  return (
    <>
      {isNavBarV2 ? (
        <div
          className={`navBar ${expandNav ? 'isOpen' : ''}`}
          onMouseEnter={() => setExpandNav(true)}
          onMouseLeave={() => setExpandNav(false)}
        >
          {tabList
            .filter((tab) => TAB[tab].label)
            .map((tab, index) => {
              const TabIcon = TAB[tab].icon;
              return (
                <div
                  key={`top-tab-index-${index}`}
                  className={`nav-item ${
                    activeTabIndex === index ? 'isActive' : ''
                  }`}
                  onClick={() => {
                    handleChangeV2(TAB[tab]);
                  }}
                >
                  <TabIcon />
                  <div className="tabLabel">{TAB[tab].label}</div>
                </div>
              );
            })}
        </div>
      ) : (
        <Paper className="mainTabs">
          <Tabs
            value={activeTabIndex}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            {tabList.map((tab, index) => {
              const TabIcon = TAB[tab].icon;
              return (
                <Tab
                  key={`top-tab-index-${index}`}
                  label={<div className="tabLabel">{TAB[tab].label}</div>}
                  icon={<TabIcon />}
                />
              );
            })}
          </Tabs>
          {showMenu && (
            <div className="accountUserContainer">
              <DotMenu
                Icon={MenuIcon}
                items={accountMenuItems}
                id={'accountMenu'}
              />
            </div>
          )}
        </Paper>
      )}
    </>
  );
};

export const BackButton = ({ path }) => {
  let navigate = useNavigate();
  const onClickHandler = (event, newValue) => {
    path && navigate(path);
  };
  return (
    <div>
      <Button onClick={onClickHandler}>
        <ArrowBackIcon />
      </Button>
    </div>
  );
};
