export const ROUTE_PARAM = {
  CUSTOMER_ID: 'CUSTOMER_ID',
  INSTRUCTOR_ID: 'INSTRUCTOR_ID',
  SESSION_ID: 'SESSION_UUID',
  SETTING_NAME: ':SETTING_NAME',
  ORG_ID: ':ORG_ID',
  CUSTOMER_UUID: ':CUSTOMER_UUID',
  ACTIVITY_SESSION_UUID: ':ACTIVITY_SESSION_UUID',
  PLANS: 'PLANS',
  BUSINESS: 'BUSINESS',
};

export const ROUTE = {
  HOME: '/',
  RESERVATION: '/clases',
  ORG: '/org',
  ORG_RESERVATION: `${ROUTE_PARAM.ORG_ID}/reservation`,
  VIEW_SESSION_ATTENDANTS: `${ROUTE_PARAM.ORG_ID}/view/attendants`,
  ORG_SESSION_CANCELATION: `${ROUTE_PARAM.ORG_ID}/c/${ROUTE_PARAM.CUSTOMER_UUID}/s/${ROUTE_PARAM.ACTIVITY_SESSION_UUID}/cancel`,
  PAYMENTS: '/org/:ORG_ID/payments/:PROCESSOR_NAME',
  CUSTOMERS: '/customers',
  INSTRUCTORS: '/instructors',
  NEW_CUSTOMER: '/customer',
  NEW_INSTRUCTOR: '/instructor',
  VIEW_CUSTOMER: `/customer/:${ROUTE_PARAM.CUSTOMER_ID}/view`,
  EDIT_CUSTOMER: `/customer/:${ROUTE_PARAM.CUSTOMER_ID}/edit`,
  EDIT_INSTRUCTOR: `/instructor/:${ROUTE_PARAM.INSTRUCTOR_ID}`,
  SESSIONS: '/sessions',
  NEW_SESSION: '/session',
  EDIT_SESSION: `/session/:${ROUTE_PARAM.SESSION_ID}`,
  SETTINGS: `/settings/${ROUTE_PARAM.SETTING_NAME}`,
  HELP: `/help`,
};

export const getRoutePath = (route, params) => {
  let path = route;
  for (let k in params) {
    path = path.replace(`:${k}`, params[k]);
  }
  return path;
};
