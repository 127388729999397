import React, { useState } from 'react';
import { useEffect } from 'react';
import { Search, DynamicList } from '../common';
import { HELP_CONTENT_EN, HELP_CONTENT_ES } from './constants';
import { useOrgConfig } from '../../hooks';
import './style.scss';

const FilterResults = ({ data, q }) => {
  let items_found;
  items_found = data.filter((item) => {
    item.weight = 0;
    if (item.title.toLowerCase().includes(q.toLowerCase())) {
      return item;
    }
    const q_words = q.split(' ');
    for (let i = 0; i < q_words.length; i++) {
      const word = q_words[i].toLowerCase();
      if (item.keywords.includes(word)) {
        if (item.weight) {
          item.weight = item.weight + 1;
        } else {
          item.weight = 1;
        }
      }
    }
    if (item.weight > 0) {
      return item;
    }
    return false;
  });
  items_found.sort((a, b) => b.weight - a.weight);
  return items_found.splice(0, 5);
};

const Help = () => {
  const orgConfig = useOrgConfig();
  const [items, setItems] = useState([]);
  const [activeItem, setActiveItem] = useState();

  const searchHelp = ({ q, lang }) => {
    let items_found;
    if (lang === 'EN') {
      items_found = FilterResults({ data: HELP_CONTENT_EN, q });
    } else if (lang === 'ES') {
      items_found = FilterResults({ data: HELP_CONTENT_ES, q });
    }
    if (items_found) {
      setItems(items_found);
    }
    setActiveItem(null);
  };

  useEffect(() => {
    searchHelp({ q: '', lang: 'EN' });
  }, []);

  return (
    <div className={'help-view'}>
      <Search
        placeholder={'Search'}
        onType={(value) => {
          searchHelp({ q: value, lang: 'EN' });
        }}
      />
      <div className="items_container">
        <DynamicList
          noDataPlaceholder={''}
          noHeader
          data={items}
          isLoading={false}
          columns={[
            {
              key: 'n',
              header: 'n',
              render: ({ rowData, index }) => {
                const showContent = activeItem === index ? true : false;
                return (
                  <div className="item-container">
                    <div
                      className={`title ${showContent ? 'active-title' : ''}`}
                    >
                      <div
                        onClick={() => {
                          setActiveItem(index);
                        }}
                      >
                        {' '}
                        {rowData.title}
                      </div>
                    </div>
                    {showContent && (
                      <rowData.content
                        uuid={orgConfig.entityUUID}
                        onClick={() => {}}
                      />
                    )}
                  </div>
                );
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

export default Help;
