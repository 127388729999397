import React, { useState } from 'react';
import { WeekdayOptionTiles } from '../../weekday_option_tiles';
import { useOrgConfig, useUpdateConfig } from '../../../hooks';
import { CONFIG } from '../../../constants/config';
import Button from '@mui/material/Button';
export const SessionWeekDays = () => {
  const updateConfig = useUpdateConfig();
  const orgConfig = useOrgConfig();
  const CONFIGURED_WEEK_DAYS = orgConfig
    ? orgConfig[CONFIG.SESSIONS_WEEK_DAYS]
    : null;

  const {
    value: configuredWeekDaysValue = [],
    type: configuredWeekDaysType = 'json',
  } = CONFIGURED_WEEK_DAYS || {};

  const [weekDays, setWeekDays] = useState([]);

  return (
    <div className="SessionWeekDaysContainer">
      <WeekdayOptionTiles
        initialSelectedList={configuredWeekDaysValue}
        onChange={(v) => {
          setWeekDays(v);
        }}
      />
      <div className="ctaContainer">
        <Button
          style={{ marginBottom: '10px' }}
          variant="contained"
          disabled={weekDays.length === 0}
          onClick={() => {
            updateConfig({
              name: CONFIG.SESSIONS_WEEK_DAYS,
              type: configuredWeekDaysType,
              value: weekDays
                .filter((v) => v.selected === true)
                .map((v) => v.name),
            });
          }}
        >
          Save Week Days
        </Button>
      </div>
    </div>
  );
};
