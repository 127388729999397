import React from 'react';
import Button from '@mui/material/Button';
import { ActionSection } from '../common';
import { ROUTE, ROUTE_PARAM, getRoutePath } from '../../constants/routes';
import { useNavigate } from 'react-router-dom';

const CustomerListRow = ({
  uuid,
  firstName,
  status,
  onClickPurchasePlan,
  isSelected,
  onDelete,
}) => {
  let navigate = useNavigate();

  const onView = () => {
    navigate(
      getRoutePath(ROUTE.VIEW_CUSTOMER, { [ROUTE_PARAM.CUSTOMER_ID]: uuid })
    );
  };

  const noMoreAvailableSessions =
    status === 'EMAIL_VERIFICATION_PENDING' ? false : true;

  const PurchasePlanButton = () => (
    <Button
      variant="contained"
      color="primary"
      onClick={() => {
        onClickPurchasePlan({ uuid, firstName });
      }}
    >
      Purchase Plan
    </Button>
  );

  const PurchaseOtherPlanButton = () => (
    <Button
      variant="contained"
      color="primary"
      onClick={() => {
        onClickPurchasePlan({ uuid, firstName });
      }}
    >
      Purchase Other Plan
    </Button>
  );

  return (
    <div className={'customer-list-row'}>
      <div className={'customer-list-data'}>
        <div className="customer-name">{firstName}</div>
      </div>

      <ActionSection
        canView={true}
        canDelete
        onView={onView}
        onDelete={() => {
          onDelete && onDelete({ uuid, firstName });
        }}
        isWhite
      >
        <>
          {status === 'EMAIL_VERIFICATION_PENDING' ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Email Confirmation Pending
            </div>
          ) : null}
          {noMoreAvailableSessions ? (
            <PurchasePlanButton />
          ) : (
            <PurchaseOtherPlanButton />
          )}
        </>
      </ActionSection>
    </div>
  );
};

export default CustomerListRow;
