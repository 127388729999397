import React, { useState, useEffect, useCallback } from 'react';
import { usePickExpApi } from '@pickexp/core';
import { useParams } from 'react-router-dom';
import './style.scss';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const ClassPublicReservationView = () => {
  const { ORG_ID, ACTIVITY_SESSION_UUID, CUSTOMER_UUID } = useParams();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [messageType, setMessageType] = useState('');
  const api = usePickExpApi().productApi;

  const publicCancelActivityClass = useCallback(async () => {
    try {
      const r = await api.publicCustomerCancelSession({
        orgUUID: ORG_ID,
        sessionActivityUUID: ACTIVITY_SESSION_UUID,
        customerUUID: CUSTOMER_UUID,
      });
      setHasLoaded(true);
      if (r) {
        if (
          r.data &&
          r.data.message &&
          r.data.message.includes(
            'Session is too close for the customer to cancel'
          )
        ) {
          setMessageType('error-session-too-close');
        } else {
          setMessageType('success-with-refund');
        }
      } else {
        setMessageType('error');
      }
    } catch (err) {
      setMessageType('error');
      setHasLoaded(true);
    }
  }, [api, ORG_ID, ACTIVITY_SESSION_UUID, CUSTOMER_UUID]);

  useEffect(() => {
    !hasLoaded && publicCancelActivityClass();
  }, [hasLoaded, publicCancelActivityClass]);

  return (
    <div className={'public-cancelation-view'}>
      <div className="org-logo">
        <img
          src="https://ci3.googleusercontent.com/proxy/VHFy6VzCmjiTdTxKqG8PkFIbswKH-vAMR7VSHEkRdWp2Vjm1O0zYllOZjgWDHxEQljSbdz0mOC_erg8glZw4vbSTjQiS8ORZJyq08EGcumxAbLaUhz50qz73csAwStuJhxlaNS1jVsOMR439J2Y=s0-d-e1-ft#http://kpopacademystage.com/wp-content/uploads/2020/11/cropped-logo-negro-stage-509x170.png"
          alt={'org-logo'}
        ></img>
      </div>

      {messageType === 'success-with-refund' && (
        <div className="success-with-refund">
          <CheckCircleOutlineIcon /> <h1>Clase Cancelada Exitosamente</h1>
        </div>
      )}
      {messageType === 'success-with-no-refund' && (
        <div className="success-with-no-refund">
          <ErrorOutlineIcon /> <h1>Clase Cancelada</h1>
        </div>
      )}
      {messageType === 'error' && (
        <div className="error">
          <ErrorOutlineIcon />{' '}
          <h1>No se pudo cancelar su clase intente mas tarde</h1>
        </div>
      )}
      {messageType === 'error-session-too-close' && (
        <div className="error">
          <ErrorOutlineIcon />{' '}
          <h1>
            No se pudo cancelar su clase debudo a que está cerca de iniciar.
          </h1>
        </div>
      )}
    </div>
  );
};

export default ClassPublicReservationView;
