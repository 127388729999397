import React from 'react';
import './DashboardView.scss';
import { NavBar, TAB } from '../components/nav';

const DashboardView = ({ activeTabName, activeSubTabName }) => {
  const activeTabNameValue = activeTabName || TAB.SESSIONS.name;
  let activeTab = TAB[activeTabNameValue];

  if (activeSubTabName && activeTab.subTab) {
    activeTab = activeTab.subTab[activeSubTabName];
  }

  return (
    <div className="content-container">
      <NavBar activeTabName={activeTabName} />
      <div role="tabpanel" className="tabpanel">
        {activeTab && <activeTab.Render />}
      </div>
    </div>
  );
};

export default DashboardView;
