import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import './style.scss';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export const DEFAULT_CONFIRM_MODAL_STATE = {
  isOpen: false,
};

export const COMMON_TEXT = {
  ACCEPT: 'Accept',
  REJECT: 'Reject',
  YES: 'Yes',
  NO: 'No',
  CANCEL: 'Cancel',
};

export const CustomModal = ({ isOpen, children, onClose, className }) => {
  return (
    <Modal
      className={className}
      open={isOpen}
      onClose={() => {
        onClose && onClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}> {children}</Box>
    </Modal>
  );
};

export const ConfirmModal = ({
  isOpen,
  titleText,
  bodyText,
  onAccept,
  onReject,
  acceptText,
  rejectText,
  onClose,
}) => {
  const [isOnReserveDisabled, setIsOnReserveDisabled] = useState(false);

  const handleAccept = () => {
    setIsOnReserveDisabled(true);
    setTimeout(function () {
      setIsOnReserveDisabled(false);
    }, 3000);

    onAccept && onAccept();
  };

  const handleReject = () => {
    onReject && onReject();
  };

  return (
    <Modal
      className={'common-confirm-modal'}
      open={isOpen}
      onClose={() => {
        onClose && onClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {titleText && <h2>{titleText}</h2>}
        {bodyText && <div className="confirmModalBody">{bodyText}</div>}

        <div className={'modalActionContainer'}>
          {rejectText && (
            <Button
              onClick={handleReject}
              className={'commonSecondaryButton'}
              variant="contained"
              type="button"
            >
              {rejectText}
            </Button>
          )}
          {acceptText && (
            <Button
              onClick={handleAccept}
              className={'commonPrimaryButton'}
              variant="contained"
              type="button"
              disabled={isOnReserveDisabled}
            >
              {acceptText}
            </Button>
          )}
        </div>
      </Box>
    </Modal>
  );
};
