import moment from 'moment';

const SECONDS_TO_MILLISECONDS = 1000;

export const OPTION = {
  SECONDS: 'seconds',
  MINUTES: 'minutes',
  HOURS: 'hours',
  DAYS: 'days',
};

export const SPANISH_WEEKDAY_NAME = {
  Sunday: 'Domingo',
  Monday: 'Lunes',
  Tuesday: 'Martes',
  Wednesday: 'Miercoles',
  Thursday: 'Jueves',
  Friday: 'Viernes',
  Saturday: 'Sabado',
};

export const SPANISH_MONTHS_NAME = {
  January: 'Enero',
  February: 'Febrero',
  March: 'Marzo',
  April: 'Abril',
  May: 'Mayo',
  June: 'Junio',
  July: 'Julio',
  August: 'Agosto',
  September: 'Septiembre',
  October: 'Octubre',
  November: 'Noviembre',
  December: 'Diciembre',
};

export const DATE_FORMAT = {
  FE_DATE: 'YYYYMMDDThhmmss',
};

export const unixToMoment = (date) => {
  return moment(date * SECONDS_TO_MILLISECONDS);
};

export const formattedDate = (date = new Date(), format) => {
  return moment(date).format(format || 'LL');
};

export const feFormattedDate = (date, format) => {
  return moment
    .utc(date)
    .local()
    .format(format || 'LL');
};

export const unixToDate = (date) => {
  return unixToMoment(date).toDate();
};

export const unixToFormat = (date, format) => {
  return unixToMoment(date).format(format);
};

export const unixDateDiff = (date1, date2, value) => {
  return unixToMoment(date1).diff(unixToMoment(date2), value);
};

export const UTCToLocalMoment = (date) => {
  return moment.utc(date).local();
};

export const UTCToLocalFormat = (date, format) => {
  return UTCToLocalMoment(date).format(format);
};

export const UTCToLocalDateDiff = (date1, date2, value) => {
  return UTCToLocalMoment(date1).diff(UTCToLocalMoment(date2), value);
};

export const currentDiffInHours = (inDate) => {
  return moment(inDate).diff(new Date(), OPTION.HOURS);
};
export const currentDiffInMinutes = (inDate) => {
  return moment(inDate).diff(new Date(), OPTION.MINUTES);
};

export const UTCToCurrentDiffInMinutes = (date) => {
  return moment(date).utc().diff(new Date(), OPTION.MINUTES);
};

export const UTCToCurrentDiffInHours = (date) => {
  return moment(date).utc().diff(new Date(), OPTION.HOURS);
};

export const UTCToCurrentDiffInDays = (date) => {
  return moment(date).utc().diff(new Date(), OPTION.DAYS);
};

export const UTCToCurrentDiff = (date) => {
  const a = new Date(date).getTime();
  const b = moment().utc().toDate().getTime();
  let diffMs = a - b;

  let diffDays = Math.floor(diffMs / 86400000); // days
  let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
  let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

  return {
    minutes: diffMins,
    hours: diffHrs,
    days: diffDays,
  };
};

export const getUTCDate = (date) => {
  return moment(date).utc();
};

export const getCurrentUTCDate = (format) => {
  return moment().utc().format(format);
};

export const isCurrentDateInBeetween = (start, end) => {
  const s = UTCToLocalMoment(start).toDate();
  const e = UTCToLocalMoment(end).toDate();
  const c = moment().toDate();
  return c.getTime() >= s.getTime() && c.getTime() <= e.getTime()
    ? true
    : false;
};

export const isPassTime = (end) => {
  const e = UTCToLocalMoment(end).toDate();
  const c = moment().toDate();
  return c.getTime() > e.getTime() ? true : false;
};

export const LocalToUTCFormat = (date, format) => {
  return getUTCDate(date).format(format);
};

export const getDateUTCRange = (date, format) => {
  return {
    startDate: moment(date).startOf('day').utc().format(format),
    endDate: moment(date).endOf('day').utc().format(format),
  };
};

export const getWeekDayName = (date) => {
  return date ? moment(date).format('dddd') : moment().format('dddd');
};

export const getCurrentUTCWithMinutesRange = (minutes, format) => {
  return {
    startDate: moment().utc().format(format),
    endDate: moment().add(minutes, 'minutes').utc().format(format),
  };
};

export const getUTCWithMinutesRange = ({ startDate, minutes, format }) => {
  return {
    startDate: moment(startDate).utc().format(format),
    endDate: moment(startDate)
      .add(minutes, 'minutes')
      .endOf('day')
      .utc()
      .format(format),
  };
};

export const LocalToUTCEndOfDayFormat = (date) => {
  return moment(date).endOf('day').utc().format();
};

export const getWithMinutesRange = ({ startDate, minutes, format }) => {
  return {
    startDate: moment(startDate).format(format),
    endDate: moment(startDate)
      .add(minutes, 'minutes')
      .endOf('day')
      .format(format),
  };
};

export const getWeekDays = () => {
  const weekDays = [];
  const date = moment();
  for (let i = 0; i < 7; i++) {
    const formattedDate =
      i === 0 ? date.format('LLLL') : date.add(1, 'd').format('LLLL');
    const datePart = formattedDate.split(',');
    weekDays.push({
      dayName: datePart[0],
      monthName: datePart[1],
      day: date.date(),
      month: date.month() + 1,
      year: date.year(),
      formatedDate: date.format(),
    });
  }
  return weekDays;
};
