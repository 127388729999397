export const CONFIG = {
  SYNCED_SESSIONS: 'SYNCED_SESSIONS',
  SESSIONS_WEEK_DAYS: 'SESSIONS_WEEK_DAYS',
  MIN_RESERVATION_MINUTES: 'MIN_RESERVATION_MINUTES',
  FORWARD_RESERVATION_WINDOW: 'FORWARD_RESERVATION_WINDOW',
  ACCESS: 'ACCESS',
};

export const DEFAULT_ACCESS_VALUES = {
  canUseInstructors: true,
  canUseWeeklyEvents: true,
  canUseForwardReservationDays: true,
  canUseChatSupport: false,
  canViewDashboard: false,
  maxCustomersLimit: 3,
  maxMontlySessionsLimit: -1,
  maxAllowedUsers: 1,
  maxCustomerDataRetentionDays: 22,
};

export const DEFAULT_INFO_VALUES = {
  lang: 'EN',
};

export const DEFAULT_APP_PLANS = [
  {
    name: 'free',
    startDate: '',
    expDate: '',
    currency: 'USD',
    amount: 0,
    isActive: true,
    features: {
      customersLimit: 5,
      manageInstructors: false,
      manageCustomerPlans: true,
      manageCustomerEmails: true,
      customerReservationDataRetentionDays: 45,
      adminUsers: 1,
      ticketSupport: true,
      chatSupport: false,
    },
  },
  {
    name: 'mini',
    startDate: '',
    expDate: '',
    currency: 'USD',
    amount: 1999,
    isActive: false,
    features: {
      customersLimit: 150,
      manageInstructors: true,
      manageCustomerPlans: true,
      manageCustomerEmails: true,
      customerReservationDataRetentionDays: 180,
      adminUsers: 1,
      ticketSupport: true,
      chatSupport: false,
    },
  },
  {
    name: 'start',
    startDate: '',
    expDate: '',
    currency: 'USD',
    amount: 4999,
    isActive: false,
    features: {
      customersLimit: 400,
      manageInstructors: true,
      manageCustomerPlans: true,
      manageCustomerEmails: true,
      customerReservationDataRetentionDays: 365,
      adminUsers: 2,
      ticketSupport: true,
      chatSupport: false,
    },
  },
  {
    name: 'grow',
    startDate: '',
    expDate: '',
    currency: 'USD',
    amount: 8999,
    isActive: false,
    features: {
      customersLimit: 800,
      manageInstructors: true,
      manageCustomerPlans: true,
      manageCustomerEmails: true,
      customerReservationDataRetentionDays: 365,
      adminUsers: 2,
      ticketSupport: true,
      chatSupport: true,
    },
  },
  {
    name: 'jumbo',
    startDate: '',
    expDate: '',
    currency: 'USD',
    amount: 16900,
    isActive: false,
    features: {
      customersLimit: 1600,
      manageInstructors: true,
      manageCustomerPlans: true,
      manageCustomerEmails: true,
      customerReservationDataRetentionDays: 365,
      adminUsers: 2,
      ticketSupport: true,
      chatSupport: true,
    },
  },
];

export const MIN_RESERVATION_TIME_LIST = [
  {
    label: '15 minutes',
    value: 15,
  },
  {
    label: '30 Minutes',
    value: 30,
  },
  {
    label: '45 Minutes',
    value: 45,
  },
  {
    label: '1 Hour',
    value: 60,
  },
  {
    label: '2 Hours',
    value: 120,
  },
  {
    label: '3 Hours',
    value: 180,
  },
  {
    label: '6 Hours',
    value: 360,
  },
  {
    label: '12 Hours',
    value: 720,
  },
  {
    label: '1 Day',
    value: 1440,
  },
];
