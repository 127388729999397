import React from 'react';
import { ACCESS_LEVEL } from './constants/access';
import { ReactSVG } from 'react-svg';

export const getListeners = () => ({
  onClick: ({ data }) => ({ data }),
});

export const getAccessLevel = (name) => {
  return name ? ACCESS_LEVEL[name] : ACCESS_LEVEL.df;
};

export const Icon = ({ name, size, onClick }) => {
  return (
    <ReactSVG
      src={`/assets/bootstrap/icons/${name}.svg`}
      beforeInjection={(svg) => {
        //svg.classList.add('svg-class-name');
        const styleSize = `width: ${size};height: ${size}`;
        svg.setAttribute('style', styleSize);
      }}
      onClick={onClick}
    />
  );
};

export const ProductLogo = ({ name, sWidth, sHeight, onClick }) => {
  return (
    <ReactSVG
      src={`/assets/images/products/bookflow/${name}.svg`}
      beforeInjection={(svg) => {
        const styleSize = `width: ${sWidth};height: ${sHeight};`;
        svg.setAttribute('style', styleSize);
      }}
      onClick={onClick}
    />
  );
};

export const IMAGE_FORMAT = {
  PNG: 'png',
};

export const ClientLogo = ({
  clientName,
  imageName,
  format,
  width,
  height,
  onClick,
}) => {
  return IMAGE_FORMAT.PNG ? (
    <img
      src={`/assets/images/${clientName}/${imageName}.${format}`}
      alt={`${clientName}-${imageName}`}
      width={width}
      height={height}
    />
  ) : (
    <ReactSVG
      src={`/assets/images/${clientName}/${imageName}.svg`}
      beforeInjection={(svg) => {
        const styleSize = `width: ${width};height: ${height};`;
        svg.setAttribute('style', styleSize);
      }}
      onClick={onClick}
    />
  );
};

export const formHandler = () => {
  return Object.assign(
    {},
    {
      form: {},
      setFormData: function (key, value) {
        if (key && (value || value === '')) {
          this[key] = value;
        }
      },
      getForm: function (key) {
        return key ? this[key] : this;
      },
    }
  );
};

export const getFormData = ({ data, baseSelector }) => {
  const form = Object.assign(data, {});
  const assignFormData = (list) => {
    for (let i = 0; i < list.length; i++) {
      const el = list[i];
      const dataGroup = el.getAttribute('data-group');
      const dataName = el.getAttribute('data-name');
      const { tagName, name, value, innerHTML } = el;
      const useKey = dataName ? dataName : name;
      if (tagName && tagName !== 'DIV') {
        if (useKey && dataGroup) {
          form[dataGroup][useKey] = value;
        } else {
          form[useKey] = value;
        }
      } else {
        form[useKey] = innerHTML;
      }
    }
  };
  let l = document.querySelectorAll(`${baseSelector} input`);
  assignFormData(l);
  l = document.querySelectorAll(`${baseSelector} select`);
  assignFormData(l);
  l = document.querySelectorAll(`${baseSelector} [contenteditable="true"]`);
  assignFormData(l);
  return form;
};

export const RenderHTML = ({ className, text }) => {
  if (text) {
    if (
      (text && text.indexOf('<script') !== -1) ||
      text.indexOf('xss') !== -1 ||
      text.indexOf('alert') !== -1
    ) {
      text = 'Error';
    }
  }
  return (
    <div className={className} dangerouslySetInnerHTML={{ __html: text }}></div>
  );
};

export const isResponsive = () => {
  const MIN_WIDTH = 700;
  return window.screen.width < MIN_WIDTH;
};

export const LOCAL_STORAGE_APP_STATE = {};

export const LOCAL_STORAGE_APP_INITIAL_STATE = {
  [LOCAL_STORAGE_APP_STATE.SESSIONS_SYNCED]: false,
};

export const setLocalStorageState = (name, value) => {
  const keyName = 'lsas';
  const props = window.localStorage.getItem(keyName);
  if (props !== null) {
    let propsObj = {};
    try {
      propsObj = JSON.parse(props);
      propsObj[name] = value;
      window.localStorage.setItem(keyName, JSON.stringify(propsObj));
    } catch (err) {}
  } else {
    window.localStorage.setItem(
      keyName,
      JSON.stringify(LOCAL_STORAGE_APP_INITIAL_STATE)
    );
  }
};

export const getLocalStorageState = (name) => {
  const keyName = 'lsas';
  const props = window.localStorage.getItem(keyName);
  let propsObj = {};
  try {
    propsObj = JSON.parse(props);
    if (propsObj[name]) {
      return propsObj[name];
    }
    return propsObj;
  } catch (err) {
    return propsObj;
  }
};
