import * as React from 'react';
import Button from '@mui/material/Button';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import './style.scss';

export const ActionSection = ({
  canEdit,
  canDelete,
  canView,
  customList,
  onEdit,
  onDelete,
  onView,
  isWhite,
  index,
  children,
}) => {
  return (
    <div
      className={`action-section ${isWhite ? 'selected' : ''}`}
      key={`ac-c-${index}`}
    >
      {children}

      {canView && (
        <Button
          onClick={onView}
          className={'view-button'}
          type="button"
          startIcon={<RemoveRedEyeIcon />}
        >
          View
        </Button>
      )}
      {canEdit && (
        <Button
          onClick={onEdit}
          className={'edit-button'}
          type="button"
          startIcon={<CreateIcon />}
        >
          Edit
        </Button>
      )}
      {canDelete && (
        <Button
          onClick={onDelete}
          className={'delete-button'}
          type="button"
          startIcon={<DeleteIcon />}
        >
          Delete
        </Button>
      )}
      {customList &&
        customList.map((b) => {
          return b;
        })}
    </div>
  );
};
