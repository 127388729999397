import React, { useState } from 'react';
import { Formik } from 'formik';
import { usePickExpApi } from '@pickexp/core';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import {
  SectionHeader,
  ConfirmModal,
  DEFAULT_CONFIRM_MODAL_STATE,
  COMMON_TEXT,
} from '../common';
import { ROUTE } from '../../constants/routes';
import { useNavigate } from 'react-router-dom';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const CustomerForm = ({ customer = {}, onSave }) => {
  const api = usePickExpApi().productApi;

  let navigate = useNavigate();
  const initialCustomerValues = {
    firstName: customer.firstName || '',
    lastName: customer.lastName || '',
    email: customer.email || '',
  };

  if (customer.uuid) {
    initialCustomerValues.uuid = customer.uuid;
  }

  const navigateToCustomer = async () => {
    navigate(ROUTE.CUSTOMERS);
  };

  const validateForm = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    if (!values.firstName) {
      errors.firstName = 'First name is required';
    }
    return errors;
  };

  const createOrUpdateCustomer = async (values) => {
    if (initialCustomerValues.email === values.email) {
      delete values.email;
    }

    const { data } = await api.createOrUpdateCustomer(values);
    onSave && onSave(data);
    return data.status === 'Success';
  };

  const [openConfirmModal, setOpenConfirmModal] = useState(
    DEFAULT_CONFIRM_MODAL_STATE
  );

  const onFormSubmitHandler = async (values, { setSubmitting }) => {
    setOpenConfirmModal({
      isOpen: true,
      onAcceptCallback: async () => {
        await createOrUpdateCustomer(values);
        setSubmitting(false);
        navigateToCustomer();
      },
      onRejectCallback: () => setOpenConfirmModal({ isOpen: false }),
    });
  };

  return (
    <Formik
      initialValues={initialCustomerValues}
      validate={validateForm}
      onSubmit={onFormSubmitHandler}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <SectionHeader title={'New Customer'} />
          <div className="customerFormInputs">
            <input
              type="text"
              name="firstName"
              placeholder={'First Name'}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.firstName}
            />
            <input
              type="text"
              name="lastName"
              placeholder={'Last Name'}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.lastName}
            />
            <input
              type="email"
              name="email"
              placeholder={'Email Name'}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />

            {values.email !== initialCustomerValues.email &&
              initialCustomerValues.email !== '' && (
                <div className="formWraningMessage">
                  <WarningAmberIcon />
                  <div>
                    Changing customer email will trigger a verification flow,
                    making the customer not visible until email has been
                    verified by the customer.
                  </div>
                </div>
              )}
            <div className={'feedbackContainer'}>
              <div> {errors.email && touched.email && errors.email}</div>
              <div>
                {errors.firstName && touched.firstName && errors.firstName}
              </div>
            </div>
            <div className={'actionContainer'}>
              <div className={'actionButtons'}>
                <Button
                  variant="contained"
                  startIcon={<ArrowBackIcon />}
                  onClick={navigateToCustomer}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={<SaveIcon />}
                  disabled={
                    isSubmitting ||
                    values.firstName === '' ||
                    values.email === ''
                  }
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
          <ConfirmModal
            isOpen={openConfirmModal.isOpen}
            bodyText={`Create customer ${values.firstName}?`}
            onAccept={openConfirmModal.onAcceptCallback}
            acceptText={COMMON_TEXT.YES}
            onReject={openConfirmModal.onRejectCallback}
            rejectText={COMMON_TEXT.NO}
          />
        </form>
      )}
    </Formik>
  );
};

export default CustomerForm;
