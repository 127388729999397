import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import CheckIcon from '@mui/icons-material/Check';
import SvgIcon from '@mui/material/SvgIcon';
import EventIcon from '@mui/icons-material/Event';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';

import './style.scss';

export const WeekdayOptionTiles = ({
  onChange,
  onStartSelection,
  initialSelectedList,
  allowList,
  useRangeSelection,
}) => {
  const weekArray = moment
    .weekdays()
    .filter((v) => (allowList ? allowList.includes(v) : true));

  const lastIndex = initialSelectedList ? initialSelectedList.length - 1 : 0;

  const getRange = ({ startDay, lastDay }) => {
    return {
      startDay,
      lastDay,
      startDayIndex: startDay && weekArray.indexOf(startDay),
      lastDayIndex: lastDay && weekArray.indexOf(lastDay),
    };
  };

  const [range, setRange] = useState(
    getRange({
      startDay: initialSelectedList && initialSelectedList[0],
      lastDay: initialSelectedList && initialSelectedList[lastIndex],
    })
  );

  const { startDay, lastDay, startDayIndex, lastDayIndex } = range;

  const reloadedWeekDays = weekArray.map((v, index) => {
    let isSelected = initialSelectedList
      ? initialSelectedList.includes(v)
      : false;

    if (useRangeSelection) {
      if (lastDay !== undefined) {
        if (startDayIndex < lastDayIndex) {
          isSelected = index >= startDayIndex && index <= lastDayIndex;
        } else {
          isSelected = index <= lastDayIndex || index >= startDayIndex;
        }
      } else {
        isSelected = v === startDay ? true : false;
      }
    }

    return {
      name: v,
      selected: isSelected,
      isStartDay: useRangeSelection && v === startDay,
      isLastDay: useRangeSelection && v === lastDay,
    };
  });

  const [weekDays, setWeekDays] = useState(reloadedWeekDays);

  const [newSelectedStartDay, setNewSelectedStartDay] = useState(null);
  const [newSelectedLastDay, setNewSelectedLastDay] = useState(null);

  const getIcon = ({ isStartDay, isLastDay, selected }) => {
    if (isStartDay) {
      return <SvgIcon component={FirstPageIcon} inheritViewBox />;
    } else if (isLastDay) {
      return <SvgIcon component={LastPageIcon} inheritViewBox />;
    } else {
      return (
        <SvgIcon component={selected ? CheckIcon : EventIcon} inheritViewBox />
      );
    }
  };

  const data = useRangeSelection ? reloadedWeekDays : weekDays;

  return (
    <div className="weekday_option_tiles">
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          '& > :not(style)': {
            m: 5,
            width: 150,
            height: 150,
          },
        }}
      >
        {data &&
          data.map((day, index) => (
            <Paper
              key={`week-days-item-${index}`}
              className={day.selected ? 'selected' : ''}
              elevation={3}
              style={
                useRangeSelection && newSelectedStartDay === day.name
                  ? { cursor: 'not-allowed' }
                  : null
              }
              onClick={() => {
                if (useRangeSelection) {
                  if (newSelectedStartDay === null) {
                    onStartSelection && onStartSelection();
                    setNewSelectedStartDay(day.name);
                    setRange(
                      getRange({
                        startDay: day.name,
                      })
                    );
                  }

                  if (newSelectedStartDay && newSelectedLastDay === null) {
                    if (newSelectedStartDay === day.name) {
                      return;
                    }

                    setNewSelectedLastDay(day.name);
                    const nv = [newSelectedStartDay, day.name];
                    onChange && onChange(nv);
                    setRange(
                      getRange({
                        startDay: newSelectedStartDay,
                        lastDay: day.name,
                      })
                    );
                    setNewSelectedStartDay(null);
                    setNewSelectedLastDay(null);
                  }
                } else {
                  const nv = [
                    ...weekDays.map((v) => {
                      if (v.name === day.name) {
                        v.selected = !v.selected;
                      }
                      return v;
                    }),
                  ];
                  onChange && onChange(nv);
                  setWeekDays(nv);
                }
              }}
            >
              {getIcon(day)}
              <div>
                {day.isStartDay || day.isLastDay ? (
                  <>
                    <div className="isMobileStartOrEndDayMark">
                      {day.isStartDay && `(From) - ${day.name}`}{' '}
                      {day.isLastDay && `(To) - ${day.name}`}
                    </div>
                    <div className="isStartOrEndDayMark">{day.name}</div>
                  </>
                ) : (
                  day.name
                )}
              </div>
            </Paper>
          ))}
      </Box>
    </div>
  );
};
