import React, { useEffect, useState, useCallback } from 'react';
import { usePickExpApi } from '@pickexp/core';
import { useQuery } from '../../hooks/useQuery';
import CheckIcon from '@mui/icons-material/Check';
import Button from '@mui/material/Button';

import './style.scss';

const TKAttendanceView = () => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [selectedList, setSelectedList] = useState([]);
  const [data, setData] = useState({});
  const api = usePickExpApi().productApi;

  let query = useQuery();
  const ptk = query.get('ptk');
  localStorage.setItem('ptk', ptk);

  const getAttendants = useCallback(async () => {
    const { data } = await api.getSessionCustomersByRangeView({});
    setData(data);
    setHasLoaded(true);
  }, [api]);

  const confirmSessionAttendants = async () => {
    await api.confirmSessionAttendants({
      list: selectedList.map((v) => ({
        status: 'ATTENDED',
        activityUUID: v,
      })),
    });
    getAttendants();
  };

  useEffect(() => {
    ptk && !hasLoaded && getAttendants();
  }, [hasLoaded, ptk, getAttendants]);

  return (
    <div className={'tk-attendants-view'}>
      <div className="header">
        <h2>{data && data.session && data.session.sessionName}</h2>
      </div>
      <div className="attendants">
        {data &&
          data.customers &&
          data.customers.map((c) => {
            const hasAttended = c.hasAttended === 1 ? true : false;
            const isSelected =
              selectedList && selectedList.includes(c.activityUUID);

            return (
              <div
                className="attendants-row"
                onClick={() => {
                  if (!hasAttended) {
                    if (isSelected) {
                      const nl = selectedList.filter(
                        (v) => v !== c.activityUUID
                      );
                      setSelectedList(nl);
                    } else {
                      setSelectedList([...selectedList, c.activityUUID]);
                    }
                  }
                }}
                style={{
                  minHeight: '40px',
                  display: 'flex',
                  padding: '10px',
                  cursor: hasAttended ? 'not-allowed' : 'pointer',
                  color: hasAttended ? '#aeaeae' : '',
                }}
              >
                {(isSelected || hasAttended) && <CheckIcon />}
                <div>{c.firstName}</div>
              </div>
            );
          })}
      </div>
      <div className="actions">
        <Button onClick={confirmSessionAttendants} type="button">
          Save
        </Button>
      </div>
    </div>
  );
};

export default TKAttendanceView;
