export const SECONDS_PER_MINUTES = 60;
export const sessionDurationOptions = [
  {
    value: 15 * SECONDS_PER_MINUTES,
    label: '15 Minutes',
  },
  {
    value: 30 * SECONDS_PER_MINUTES,
    label: '30 Minutes',
  },
  {
    value: 45 * SECONDS_PER_MINUTES,
    label: '45 Minutes',
  },
  {
    value: 60 * SECONDS_PER_MINUTES,
    label: '1 Hour',
  },
  {
    value: 90 * SECONDS_PER_MINUTES,
    label: '1 Hour and 30 Minutes',
  },
  {
    value: 120 * SECONDS_PER_MINUTES,
    label: '2 Hour',
  },
  {
    value: 150 * SECONDS_PER_MINUTES,
    label: '2 Hour and 30 Minutes',
  },
  {
    value: 180 * SECONDS_PER_MINUTES,
    label: '3 Hour',
  },
  {
    value: 240 * SECONDS_PER_MINUTES,
    label: '4 Hour',
  },
];

export const STEP = {
  SINGLE_EVENT: 'SINGLE_EVENT',
  WEEKLY_EVENT: 'WEEKLY_EVENT',
  COMPLETE_FORM: 'COMPLETE_FORM',
};

export const STEPS = {
  title: 'Select session creation type',
  [STEP.WEEKLY_EVENT]: {
    title: 'Select weekdays',
    next: STEP.COMPLETE_FORM,
  },
  [STEP.COMPLETE_FORM]: {
    title: 'Complete form',
    back: STEP.WEEKLY_EVENT,
  },
};
