import React, { useState, useEffect } from 'react';
import Help from '../help';
import './style.scss';
import { usePickExpApi } from '@pickexp/core';
import { ProgressContainer, SectionHeader } from '../common';
import CachedIcon from '@mui/icons-material/Cached';
import { feFormattedDate } from '../../utils/date-utils';
import { useOrgConfig } from '../../hooks';

const DashInfoCard = ({
  value,
  title,
  subTitle,
  icon,
  valueColorOverride,
  onClick,
}) => {
  return (
    <div className={'dash-info-card'}>
      <div className="card-title">{title}</div>
      <div className="card-value" style={{ color: valueColorOverride }}>
        {value}
      </div>
    </div>
  );
};

const Dashboard = () => {
  const MESSAGE_COLOR = {
    GOOD: '#3cb371',
    WARNING: '#ebc700',
    ALERT: '#ce2029',
  };
  let planPercentageUsage = 0;
  let planPercentageUsageColor = MESSAGE_COLOR.GOOD;
  let inactiveCustomersPercentage = 0;
  let inactiveCustomersColor = MESSAGE_COLOR.GOOD;

  const api = usePickExpApi().productApi;
  const orgConfig = useOrgConfig();

  const [dashboardData, setDashboardData] = useState();
  const [loading, setLoading] = useState(true);

  const getDahboardData = async ({ isRefresh }) => {
    const { data } = await api.getEntityDahboardReportView({
      isRefresh,
    });
    setDashboardData(data);
    setLoading(false);
    if (isRefresh) {
      window.location.reload();
    }
  };

  useEffect(() => {
    !dashboardData && getDahboardData({ isRefresh: false });
  });

  if (orgConfig && dashboardData) {
    if (orgConfig.maxCustomersLimit && dashboardData.totalCustomers > 0) {
      planPercentageUsage =
        (dashboardData.totalCustomers / orgConfig.maxCustomersLimit) * 100;
      if (planPercentageUsage > 70 && planPercentageUsage <= 85) {
        planPercentageUsageColor = MESSAGE_COLOR.WARNING;
      } else if (planPercentageUsage >= 85) {
        planPercentageUsageColor = MESSAGE_COLOR.ALERT;
      }
    }

    if (dashboardData.totalInactiveCustomers > 0) {
      inactiveCustomersPercentage =
        (dashboardData.totalInactiveCustomers / dashboardData.totalCustomers) *
        100;

      inactiveCustomersPercentage = 55;

      if (
        inactiveCustomersPercentage > 25 &&
        inactiveCustomersPercentage <= 50
      ) {
        inactiveCustomersColor = MESSAGE_COLOR.WARNING;
      } else if (inactiveCustomersPercentage >= 50) {
        inactiveCustomersColor = MESSAGE_COLOR.ALERT;
      }
    }
  }

  return (
    <div className={'dashboard-view inner-condition-container'}>
      <SectionHeader
        title={'Dashboard'}
        subTitle={
          dashboardData
            ? `Updated at ${feFormattedDate(dashboardData.date, 'LLLL')}`
            : ''
        }
        endIcon={
          <CachedIcon
            className="cachedIcon"
            onClick={() => {
              getDahboardData({ isRefresh: true });
            }}
          />
        }
      />
      <ProgressContainer condition={loading}>
        {dashboardData && (
          <>
            <div className="info-cards-container">
              <DashInfoCard
                title={'Customers'}
                value={dashboardData.totalCustomers}
              />
              <DashInfoCard
                title={'Instructors'}
                value={dashboardData.totalInstructors}
              />
              {dashboardData.totalCustomers > 25 && (
                <DashInfoCard
                  title={'Inactive Customers'}
                  value={`${inactiveCustomersPercentage}%`}
                  valueColorOverride={inactiveCustomersColor}
                />
              )}
              {orgConfig && orgConfig.maxCustomersLimit && (
                <DashInfoCard
                  title={'Plan Usage'}
                  value={`${planPercentageUsage}%`}
                  valueColorOverride={planPercentageUsageColor}
                />
              )}
            </div>
          </>
        )}
      </ProgressContainer>

      <SectionHeader title={'Questions'} subTitle={'search and find help'} />
      <Help />
    </div>
  );
};

export default Dashboard;
