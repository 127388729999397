export const THEME = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  CUSTOM: 'CUSTOM',
};

export const THEME_PROP = {
  PRIMARY_COLOR: 'PRIMARY_COLOR',
  SECUNDARY_COLOR: 'SECUNDARY_COLOR',
  TERTIARY_COLOR: 'TERTIARY_COLOR',
  PRIMARY_BACKGROUND_COLOR: 'PRIMARY_BACKGROUND_COLOR',
  SECUNDARY_BACKGROUND_COLOR: 'SECUNDARY_BACKGROUND_COLOR',
  PRIMARY_TEXT_COLOR: 'PRIMARY_TEXT_COLOR',
  SECUNDARY_TEXT_COLOR: 'SECUNDARY_TEXT_COLOR',
};

export const THEMES = {
  [THEME.LIGHT]: {
    [THEME_PROP.PRIMARY_COLOR]: '#cd32dc',
    [THEME_PROP.SECUNDARY_COLOR]: '',
    [THEME_PROP.TERTIARY_COLOR]: '',
    [THEME_PROP.PRIMARY_BACKGROUND_COLOR]: 'white',
    [THEME_PROP.SECUNDARY_BACKGROUND_COLOR]: 'rgb(74, 66, 83)',
    [THEME_PROP.PRIMARY_TEXT_COLOR]: 'black',
    [THEME_PROP.SECUNDARY_TEXT_COLOR]: 'white',
  },
  [THEME.DARK]: {
    [THEME_PROP.PRIMARY_COLOR]: '#cd32dc',
    [THEME_PROP.SECUNDARY_COLOR]: '',
    [THEME_PROP.TERTIARY_COLOR]: '',
    [THEME_PROP.PRIMARY_BACKGROUND_COLOR]: '#262b2f',
    [THEME_PROP.SECUNDARY_BACKGROUND_COLOR]: '',
    [THEME_PROP.PRIMARY_TEXT_COLOR]: 'white',
    [THEME_PROP.SECUNDARY_TEXT_COLOR]: '#cd32dc',
  },
  [THEME.CUSTOM]: {
    [THEME_PROP.PRIMARY_COLOR]: '#cd32dc',
    [THEME_PROP.SECUNDARY_COLOR]: '',
    [THEME_PROP.TERTIARY_COLOR]: '',
    [THEME_PROP.PRIMARY_BACKGROUND_COLOR]: 'white',
    [THEME_PROP.SECUNDARY_BACKGROUND_COLOR]: 'rgb(74, 66, 83)',
    [THEME_PROP.PRIMARY_TEXT_COLOR]: 'black',
    [THEME_PROP.SECUNDARY_TEXT_COLOR]: '#cd32dc',
  },
};
