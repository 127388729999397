import React, { Component } from 'react';
import $ from 'jquery';
import './PeReactModal.scss';
import { Icon } from '../../utils';

export const MODAL_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning',
  CUSTOM: 'custom',
  CONFIRM: 'confirm',
  BIG: 'big',
};

class ModalComponentReact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      modalType: MODAL_TYPE.INFO,
      CustomComponent: () => {
        return <div></div>;
      },
      title: '',
      text: '',
      cb: () => {},
      buttonA: { text: 'YES', action: this.hide },
      buttonB: { text: 'NO', action: this.hide },
      data: {},
    };
    this.showModal = this.showModal.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.showSimple = this.showSimple.bind(this);
    this.hide = this.hide.bind(this);
    this.config = this.config.bind(this);
    this.t = props.t;
    const modalId = this.getModalId();
    $(modalId).on('shown.bs.modal', function () {
      const el = $(modalId + ' .modal-body input');
      if (el) {
        el.trigger('focus');
      }
    });
    $(modalId).on('hidden.bs.modal', function () {
      this.hideModal(modalId);
    });
  }

  getModalId() {
    return '#my-react-modal-id';
  }

  config({ component, type, title, text, button, cb, buttonA, buttonB, data }) {
    this.setState({
      CustomComponent: component,
      modalType: type,
      title,
      text,
      button,
      cb,
      buttonA,
      buttonB,
      data,
    });
  }

  showModal() {
    const modalId = this.getModalId();
    $(modalId).modal({ backdrop: 'static', keyboard: false, show: true });
  }

  showConfirm(title, text, buttonA, buttonB) {
    buttonA = Object.assign(
      { text: this.t('common.yes'), action: this.hide },
      buttonA
    );
    buttonB = Object.assign(
      { text: this.t('common.no'), action: this.hide },
      buttonB
    );

    const config = {
      type: MODAL_TYPE.CONFIRM,
      title,
      text,
      buttonA,
      buttonB,
    };
    this.config(config);
    this.showModal();
  }

  showSimple(
    title,
    text,
    type = 'info',
    buttonA = { text: 'OK', action: this.hide }
  ) {
    const ctx = this;
    ctx.hide();
    setTimeout(function () {
      ctx.showSimpleOver(title, text, type, buttonA);
    }, 500);
  }

  showSimpleOver(
    title,
    text,
    type = 'info',
    buttonA = { text: 'OK', action: this.hide }
  ) {
    const config = {
      type,
      title,
      text,
      buttonA,
    };
    this.config(config);
    this.showModal();
  }

  showCustom(component, title, cb, data) {
    const config = {
      component,
      type: MODAL_TYPE.CUSTOM,
      title,
      cb,
      data,
    };
    this.config(config);
    this.showModal();
  }

  hide() {
    const modalId = this.getModalId();
    $(modalId).modal('hide');
  }

  render() {
    const CustomComponent = this.state.CustomComponent;
    const title = this.state.title;
    const text = this.state.text;
    const buttonA = this.state.buttonA;
    const buttonB = this.state.buttonB;
    const type = this.state.modalType;
    const modalCallback = this.state.cb;
    const data = this.state.data;

    let ReactModalBody = () => {
      return <div></div>;
    };

    const ReactModalHeader = () => {
      return (
        <div className="modal-header">
          <h5 className="modal-title">{title}</h5>
          <Icon name="x" size="40px" onClick={this.hide} />
        </div>
      );
    };

    const ReactModalSimpleBody = () => {
      const buttonClass = 'pe-button-modal-' + type;
      return (
        <div>
          <div className="modal-body">{text}</div>
          <div className="modal-footer">
            <button
              type="button"
              className={buttonClass}
              onClick={buttonA.action}
              data-dismiss="modal"
            >
              {buttonA.text}
            </button>
          </div>
        </div>
      );
    };

    const ReactModalConfirmBody = () => {
      return (
        <div>
          <div className="modal-body">{text}</div>
          <div className="modal-footer">
            <button
              type="button"
              className={`pe-button-primary`}
              onClick={buttonA.action}
              data-dismiss="modal"
            >
              {buttonA.text}
            </button>
            <button
              type="button"
              className={`pe-button-secundary pe-button-modal-${type}`}
              onClick={buttonB.action}
              data-dismiss="modal"
            >
              {buttonB.text}
            </button>
          </div>
        </div>
      );
    };

    const ReactModalCustomBody = () => {
      return (
        <div className="modal-body">
          <CustomComponent cb={modalCallback} {...data} modal={this} />
        </div>
      );
    };

    if (type === MODAL_TYPE.CUSTOM) {
      ReactModalBody = ReactModalCustomBody;
    } else if (type === MODAL_TYPE.CONFIRM) {
      ReactModalBody = ReactModalConfirmBody;
    } else {
      ReactModalBody = ReactModalSimpleBody;
    }

    return (
      <div
        className={`modal fade pe-modal pe-modal-type-${type}`}
        id="my-react-modal-id"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalTitleId"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className={`modal-content modal-type-${type}`}>
            <ReactModalHeader />
            <ReactModalBody />
          </div>
        </div>
      </div>
    );
  }
}

export default ModalComponentReact;
