import React, { useState, useEffect, useCallback } from 'react';
import { usePickExpApi } from '@pickexp/core';
import CircularProgress from '@mui/material/CircularProgress';
import PlanForm from './form';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import './style.scss';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const PlanCard = ({
  name,
  value,
  sessions,
  sessionTypesList,
  sessionType,
  onClick,
  isActive,
  onActiveChange,
  index,
}) => {
  const PLANS_COLORS = [
    '#74BDCB',
    '#FFA384',
    '#EFE7BC',
    '#E7D4C0',
    '#88B2CC',
    '#658EA9',
  ];

  const planCardStyle = { cursor: 'pointer' };
  if (!isActive) {
    planCardStyle.opacity = 0.7;
  }
  return (
    <div className={'planCard'} style={planCardStyle}>
      <div
        className={'header'}
        style={{
          backgroundColor: PLANS_COLORS[index],
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            height: '0px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <FormGroup
            style={{
              position: 'relative',
              top: '-5px',
              right: '-15px',
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={isActive}
                  onChange={() => {
                    onActiveChange && onActiveChange(!isActive);
                  }}
                />
              }
              label=""
            />
          </FormGroup>
        </div>
        <div onClick={onClick}> {name}</div>
      </div>
      <div className={'planCardBody'} onClick={onClick}>
        <div className={'splitter'}>
          <div></div>
        </div>
        <div className={'amount'}>${(value / 1000).toFixed(3)}</div>
        <div className={'splitter'}>
          <div></div>
        </div>
        <div className={'sessions'}>{`${sessions} Monthly sessions`}</div>
        <div className={'splitter'}>
          <div></div>
        </div>
        <div className={'allowedSession'}>
          {sessionTypesList.map((type, index) => {
            const isAllowed = sessionType.some(
              ({ name }) => name === type.name
            );

            return (
              <div
                className={isAllowed ? 'isCheck' : 'isX'}
                key={`sessionTypesList-item-${index}`}
              >
                {isAllowed ? <CheckIcon size={20} /> : <CloseIcon size={20} />}
                {type.name}
              </div>
            );
          })}
        </div>
        <div className={'splitter'}>
          <div></div>
        </div>
        <div className={'bottomBlock'}></div>
      </div>
    </div>
  );
};

const Plans = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [planList, setPlanList] = useState([]);
  const [plan, setPlan] = useState(null);
  const [sessionTypesList, setSessionTypesList] = useState([]);
  const api = usePickExpApi().productApi;

  const getPlans = useCallback(async () => {
    setIsLoading(true);
    const { data } = await api.getPlans();
    setHasLoaded(true);
    setPlanList(data);
    setIsLoading(false);
  }, [api]);

  const createOrUpdatePlan = async (values) => {
    await api.createOrUpdatePlan(values);
    getPlans();
  };

  const backToList = () => {
    setPlan(null);
    getPlans();
  };

  useEffect(() => {
    const getSessionTypes = async () => {
      setIsLoading(true);
      const { data } = await api.getSessionTypes();
      setSessionTypesList(data);
      setIsLoading(false);
    };

    getPlans();
    getSessionTypes();
  }, [api, getPlans, hasLoaded]);

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            margin: '50px',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div className={'plansContainer'}>
          {!plan && (
            <Button
              style={{ marginBottom: '10px' }}
              variant="contained"
              onClick={() => {
                setPlan({});
              }}
            >
              Add New Plan
            </Button>
          )}
          {!plan && (
            <div className={'plans'}>
              {planList.map((plan, index) => (
                <PlanCard
                  {...plan}
                  onActiveChange={(value) => {
                    createOrUpdatePlan({
                      uuid: plan.id,
                      isActive: value,
                    });
                  }}
                  isActive={plan.isActive}
                  sessionTypesList={sessionTypesList}
                  index={index}
                  key={`plan-card-${index}`}
                  onClick={() => setPlan(plan)}
                />
              ))}
            </div>
          )}

          {plan && (
            <PlanForm data={plan} onSave={backToList} onCancel={backToList} />
          )}
        </div>
      )}
    </div>
  );
};

export default Plans;
