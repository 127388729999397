import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SessionType from './SessionType';
import Plans from './Plans';
import Business from './business';

import './style.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTE, ROUTE_PARAM } from '../../constants/routes';

const TABS = {
  BUSINESS: 'BUSINESS',
  SESSION_TYPES: 'SESSION_TYPES',
  PLANS: 'PLANS',
};

const SETTING_TABS = {
  [TABS.BUSINESS]: {
    title: 'Business',
    Render: Business,
  },
  [TABS.SESSION_TYPES]: {
    title: 'Session Types',
    Render: SessionType,
  },
  [TABS.PLANS]: {
    title: 'Plans',
    Render: Plans,
  },
};

const Settings = () => {
  let navigate = useNavigate();
  let { SETTING_NAME = '' } = useParams();
  const activeTab = SETTING_TABS[SETTING_NAME.toUpperCase()];
  const tabList = Object.keys(TABS);
  const activeTabIndex = tabList.indexOf(SETTING_NAME.toUpperCase());
  const tabChangeHandler = (event, newValue) => {
    navigate(
      ROUTE.SETTINGS.replace(
        ROUTE_PARAM.SETTING_NAME,
        tabList[newValue].toLowerCase()
      )
    );
  };

  return (
    <div>
      <div className={'settingsTabsContainer'}>
        <Tabs
          value={activeTabIndex}
          onChange={tabChangeHandler}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          {tabList.map((tab, index) => (
            <Tab key={`tab-index-${index}`} label={SETTING_TABS[tab].title} />
          ))}
        </Tabs>
      </div>
      <div className={'settingsViewContainer'}>
        {activeTab && <activeTab.Render />}
      </div>
    </div>
  );
};

export default Settings;
