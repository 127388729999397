import React, { useState } from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import {
  getWeekDays,
  UTCToLocalFormat,
  isCurrentDateInBeetween,
  OPTION,
  currentDiffInMinutes,
  SPANISH_WEEKDAY_NAME,
} from '../../utils/date-utils';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import GroupIcon from '@mui/icons-material/Group';
import {
  ProgressContainer,
  DurationLabel,
  isDurationTooLong,
  DynamicList,
  ActionSection,
} from '../../components/common';
import moment from 'moment';
import { useOrgConfigValue } from '../../hooks';
import { CONFIG } from '../../constants/config';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import './style.scss';

export const SessionTabs = ({
  onTabChange,
  onActiveSwitchChange,
  onRowClick,
  selectable,
  loading,
  data,
  onReserve,
  showSpots,
  hideFullSessions,
  actionSection,
  currentActiveTabIndex,
  overridePublicOrgConfigValue,
  noFoundPlaceholder,
}) => {
  const getTranslatedWeekDayName = (name) => {
    return SPANISH_WEEKDAY_NAME[name];
  };

  let CONFIGURED_WEEK_DAYS = useOrgConfigValue(CONFIG.SESSIONS_WEEK_DAYS);
  let FORWARD_RESERVATION_WINDOW = useOrgConfigValue(
    CONFIG.FORWARD_RESERVATION_WINDOW
  );
  let MIN_RESERVATION_TIME = useOrgConfigValue(CONFIG.MIN_RESERVATION_MINUTES); // In minutes a time for minimun time needed before a class can be reserved before the class starts. same time used for cancelation

  if (overridePublicOrgConfigValue) {
    CONFIGURED_WEEK_DAYS =
      overridePublicOrgConfigValue.SESSIONS_WEEK_DAYS &&
      overridePublicOrgConfigValue.SESSIONS_WEEK_DAYS.value;
    FORWARD_RESERVATION_WINDOW =
      overridePublicOrgConfigValue.FORWARD_RESERVATION_WINDOW &&
      overridePublicOrgConfigValue.FORWARD_RESERVATION_WINDOW.value;
    MIN_RESERVATION_TIME =
      overridePublicOrgConfigValue.MIN_RESERVATION_MINUTES &&
      overridePublicOrgConfigValue.MIN_RESERVATION_MINUTES.value;
  }

  const todayDayName = moment().format('LLLL').split(',')[0];

  let weekDays = getWeekDays().filter((v) =>
    CONFIGURED_WEEK_DAYS ? CONFIGURED_WEEK_DAYS.includes(v.dayName) : true
  );

  const getRange = ({ startDay, lastDay }) => {
    return {
      startDay,
      lastDay,
      startDayIndex:
        startDay && weekDays.findIndex((v) => v.dayName === startDay),
      lastDayIndex: lastDay && weekDays.findIndex((v) => v.dayName === lastDay),
    };
  };

  if (onReserve) {
    const [startBookingDay, lastBookingDay] = FORWARD_RESERVATION_WINDOW || [];

    const range = getRange({
      startDay: startBookingDay,
      lastDay: lastBookingDay,
    });

    if (range.startDayIndex === -1 && range.lastDayIndex === -1) {
      weekDays = [];
    } else if (range.startDayIndex < range.lastDayIndex) {
      weekDays = weekDays.filter(
        (v, i) => i >= range.startDayIndex && i <= range.lastDayIndex
      );
    } else if (range.startDayIndex > range.lastDayIndex) {
      weekDays = weekDays.filter((v, i) => i <= range.lastDayIndex);
    }

    if (!weekDays.some((v) => v.dayName === todayDayName)) {
      weekDays = [];
    }
  }

  const [selected, setSelected] = useState(null);

  const [activeTab, setActiveTab] = React.useState({
    index: currentActiveTabIndex || 0,
    ...weekDays[currentActiveTabIndex || 0],
  });

  const onTabChangeHandler = (event, newValue) => {
    const newActiveTab = weekDays[newValue];
    setActiveTab({ index: newValue, ...newActiveTab });
    onTabChange && onTabChange(newActiveTab, newValue);
  };

  const onRowClickHandler = ({ row }) => {
    if (selected && row.uuid === selected.uuid) {
      setSelected(null);
    } else {
      setSelected(row);
    }
    onRowClick && onRowClick({ row });
  };

  const getCurrentStartEndDate = ({ startDate, endDate, weekDays }) => {
    const localStartDate = UTCToLocalFormat(startDate);
    const localEndDate = UTCToLocalFormat(endDate);
    const timeDiffInMinutes = moment(localEndDate).diff(
      moment(localStartDate),
      OPTION.MINUTES
    );
    const d = { startDate, endDate };
    if (weekDays) {
      const tabDate = activeTab.formatedDate.split('T')[0];
      const rowDataStartTime = localStartDate.split('T')[1];
      d.startDate = moment(`${tabDate} ${rowDataStartTime}`).format();
      const nEndDate = moment(d.startDate)
        .add(timeDiffInMinutes, OPTION.MINUTES)
        .format();
      d.endDate = nEndDate;
    }
    return d;
  };

  const onReserveClickHandler = (rowData) => {
    const localStartDate = UTCToLocalFormat(rowData.startDate);
    const localEndDate = UTCToLocalFormat(rowData.endDate);
    const timeDiffInMinutes = moment(localEndDate).diff(
      moment(localStartDate),
      OPTION.MINUTES
    );
    const d = { ...rowData };

    if (rowData.weekDays) {
      const tabDate = activeTab.formatedDate.split('T')[0];
      const rowDataStartTime = localStartDate.split('T')[1];
      d.startDate = moment(`${tabDate} ${rowDataStartTime}`).format();
      const nEndDate = moment(d.startDate)
        .add(timeDiffInMinutes, OPTION.MINUTES)
        .format();
      d.endDate = nEndDate;
    }
    onReserve && onReserve(d);
  };

  const list = hideFullSessions
    ? data.filter((r) => r.reserved < r.spots)
    : data;

  const noDataPlaceholder = noFoundPlaceholder
    ? noFoundPlaceholder
    : weekDays.length === 0
    ? 'No clases available for reservation'
    : 'Clases no disponibles para este dia';

  let showTabs = true;
  if (noDataPlaceholder === 'Su plan ha caducado') {
    showTabs = false;
  }

  return weekDays && weekDays.length > 0 ? (
    <div className={'session-tabs-container'} style={{ overflow: 'hidden' }}>
      {showTabs && (
        <Tabs
          value={activeTab.index}
          onChange={onTabChangeHandler}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          {weekDays.map((record, index) => {
            return (
              <Tab
                label={`${getTranslatedWeekDayName(record.dayName)}`}
                key={`tab-label-${index}`}
              />
            );
          })}
        </Tabs>
      )}
      <ProgressContainer condition={loading}>
        <DynamicList
          noHeader
          data={list}
          onRowClick={onRowClickHandler}
          noDataPlaceholder={noDataPlaceholder}
          isLoading={false}
          columns={[
            {
              key: 'name',
              header: 'Class Name',
              render: ({ rowData, index }) => {
                const {
                  name,
                  startDate,
                  endDate,
                  icon,
                  uuid,
                  status,
                  weekDays,
                  typeName,
                  instructorFirstName,
                  description,
                  reserved,
                  metadata,
                  spots,
                } = rowData;

                const isSessionFull = reserved >= spots;

                const isAdminSide = !onReserve;

                const sessionWeight =
                  metadata && metadata.weight ? metadata.weight : 1;

                const currentDates = getCurrentStartEndDate({
                  startDate,
                  endDate,
                  weekDays,
                });

                const currentMinutesBeforeClass = currentDiffInMinutes(
                  currentDates.startDate
                );

                const isClassReservationWindowsValid =
                  currentMinutesBeforeClass > MIN_RESERVATION_TIME;

                const isCanceled = status === 'CANCELED';

                const isInactive = status === 'INACTIVE';

                const isWeeklyEvent = !!weekDays;

                const isRowSelected =
                  selectable &&
                  selected &&
                  selected.uuid === uuid &&
                  !isWeeklyEvent
                    ? true
                    : false;

                const isSessionInProgress =
                  isCurrentDateInBeetween(startDate, endDate) && !isWeeklyEvent;

                const canEdit = !isSessionInProgress && !isCanceled;

                const rowStyle = isSessionInProgress
                  ? { backgroundColor: '#008080' }
                  : {};

                const customList = [];

                if (reserved && reserved > 0) {
                  customList.push(
                    <Button
                      onClick={() => {
                        actionSection &&
                          actionSection.onClick &&
                          actionSection.onClick(rowData);
                      }}
                      type="button"
                      startIcon={<GroupIcon />}
                    >
                      Attendants ({reserved})
                    </Button>
                  );
                }

                if (!isCanceled && actionSection && actionSection.onCancel) {
                  customList.push(
                    <Button
                      onClick={() => {
                        actionSection.onCancel(rowData);
                      }}
                      type="button"
                      startIcon={<CancelIcon />}
                    >
                      Cancel
                    </Button>
                  );
                }

                if (actionSection && actionSection.onDelete) {
                  customList.push(
                    <div className="onDeleteButton">
                      <Button
                        onClick={() => {
                          actionSection.onDelete(rowData);
                        }}
                        type="button"
                        startIcon={<DeleteIcon />}
                      >
                        Delete
                      </Button>
                    </div>
                  );
                }

                if (actionSection && actionSection.onSendAttendants) {
                  customList.push(
                    <div className="onSendAttendants">
                      <Button
                        onClick={() => {
                          actionSection.onSendAttendants(rowData);
                        }}
                        type="button"
                        startIcon={<ForwardToInboxOutlinedIcon />}
                      >
                        Send Attendants
                      </Button>
                    </div>
                  );
                }

                const WarningMessage = () => {
                  let wmessage = '';

                  if (isDurationTooLong({ startDate, endDate, maxHours: 5 })) {
                    wmessage =
                      'Seccion duration is too long check your configuration';
                  }

                  if (wmessage) {
                    return (
                      <div className="warningMessage">
                        <WarningAmberIcon />
                        <div> {wmessage}</div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                };

                return (
                  <div
                    className={`dynamicListSessionRow session-list-row ${
                      isRowSelected || isSessionInProgress ? 'selected' : ''
                    }
                  ${selectable ? 'selectable' : ''}
                  `}
                    style={rowStyle}
                  >
                    <div className={`dynamicListSessionRowData`}>
                      {icon && (
                        <div
                          className={'dynamicListSessionCol sessionIcon'}
                        ></div>
                      )}

                      <div className={'dynamicListSessionCol sessionInfo'}>
                        {isAdminSide && <WarningMessage />}

                        <div className={'dynamicListSessionCol sessionDate'}>
                          {UTCToLocalFormat(startDate, 'LT')}
                        </div>
                        <div>
                          {name || typeName}{' '}
                          {sessionWeight > 1 && `(X${sessionWeight})`}
                          <div className={'sessionInstructorFirstName'}>
                            {instructorFirstName}
                          </div>
                          {isSessionInProgress && (
                            <AccessTimeIcon
                              style={{
                                marginLeft: '10px',
                              }}
                            />
                          )}
                          {isCanceled && (
                            <ClearOutlinedIcon
                              style={{
                                marginLeft: '10px',
                                color: isRowSelected ? '' : 'red',
                              }}
                            />
                          )}
                        </div>

                        {onActiveSwitchChange && (
                          <FormControlLabel
                            control={
                              <Switch
                                color="warning"
                                checked={isInactive ? false : true}
                                onChange={(event) => {
                                  onActiveSwitchChange &&
                                    onActiveSwitchChange({
                                      uuid,
                                      value: event.target.checked,
                                    });
                                }}
                                inputProps={{ 'aria-label': 'controlled' }}
                              />
                            }
                            label={isInactive ? 'Inactive' : 'Active'}
                          />
                        )}

                        {isRowSelected && (
                          <div className={'description'}>{description}</div>
                        )}
                        {onReserve &&
                          isClassReservationWindowsValid &&
                          !isSessionFull && (
                            <Button
                              onClick={() => onReserveClickHandler(rowData)}
                              className={'reserveButton'}
                              type="button"
                            >
                              RESERVAR
                            </Button>
                          )}

                        {isSessionFull && (
                          <div className="sessionFullMessage">
                            La clase está llena
                          </div>
                        )}

                        {!isClassReservationWindowsValid && (
                          <div className="reservationWindowsNotValidMessage">
                            <div>
                              {currentMinutesBeforeClass < 0
                                ? 'Esta clase ya ha pasado'
                                : 'Esta clase esta cerca de empezar'}
                            </div>
                          </div>
                        )}
                      </div>

                      <div className={'dynamicListSessionCol sessionDuration'}>
                        <DurationLabel
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </div>
                    </div>

                    {actionSection && (
                      <ActionSection
                        index={`ActionSection-index-${index}`}
                        canEdit={canEdit}
                        onEdit={() => {
                          actionSection &&
                            actionSection.onEdit &&
                            actionSection.onEdit(rowData);
                        }}
                        isWhite
                        customList={customList}
                      />
                    )}
                  </div>
                );
              },
            },
          ]}
        />
      </ProgressContainer>
    </div>
  ) : (
    <div
      style={{
        textAlign: 'center',
        fontSize: '20px',
        marginTop: '50px',
      }}
    >
      No class days available
    </div>
  );
};
